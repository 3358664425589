import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import privateRoute from './PrivateRoute/privateRoute.logic';
import CodePages from '../../classes/CodePages/CodePages';
import CodeSender from './CodeSender/CodeSender';
import EditPayment from './EditPayment/EditPayment';
import ExchangePage from './Exchange/Exchange';
import InitPage from './InitPage/InitPage';
import MatiPage from './MatiPage/MatiPage';
import MatiPending from './MatiPending/MatiPending';
import NewOffers from './NewOffers/newOffers';
import OpenMarket from './OpenMarket/OpenMarket';
import PersonalIdentification from './PersonalIdentification/PersonalIdentification';
import SelectAccount from './SelectAccount/SelectAccount'; // 3
import SuccessfulOperation from './SuccessfulOperation/SuccessfulOperation';
import TransferToBuy from './TransferToBuy/transferToBuy';
import WithdrawUSD from './WithdrawUSD/WithdrawUSD';

import GeneralContext from '../../context/GeneralContext';

export function MainExchangePage(props) {
  let section;
  const {
    section: section_id,
    params,
    callbackFunction,
    setGeneralValues
  } = useContext(GeneralContext);
  const [values, setValues] = useState({ section: section_id, params, callbackFunction });

  useEffect(() => {
    console.log({ section_id });
    setValues({ ...values, section: section_id });
  }, [section_id]);

  useEffect(() => {
    console.log('Init');
    init();
  }, []);

  async function init() {
    console.log({ id: localStorage.getItem('id'), token: localStorage.getItem('token') });
    let session = await privateRoute().check();
    if (session) {
      setValues({ ...values, section: section_id || props.page || CodePages.INIT_PAGE });
    } else {
      console.log('!Session');
      console.log({ id: localStorage.getItem('id'), token: localStorage.getItem('token') });
      window.location.href = `${process.env.REACT_APP_PAGE_DOMAIN}/login`;
    }
  }

  const changeStateLogin = (e) => {
    setValues({ ...values, section: CodePages.INIT_PAGE });
  };

  const logout = () => {
    localStorage.clear();
    setValues({ ...values, section: CodePages.INIT_PAGE });
  };

  switch (values.section) {
    case CodePages.EXCHANGE_PAGE:
      return (section = <ExchangePage />);
    case CodePages.CREATE_SELL_OFFER_PAGE:
      return (section = <NewOffers type={'SELL'} />);
    case CodePages.CREATE_BUY_OFFER_PAGE:
      return (section = <NewOffers type={'BUY'} />);
    case CodePages.ALL_OFFERS:
      return (section = <OpenMarket />);
    case CodePages.TRANSFER_USD_PAGE:
      return (section = <TransferToBuy />);
    case CodePages.WITHDRAW_PAGE:
      return (section = <WithdrawUSD />);
    case CodePages.MATI_PAGE:
      return (section = <MatiPage />);
    case CodePages.MATI_PENDING:
      return (section = <MatiPending />);
    case CodePages.SETUP_ACCOUNT_BANK_DATA:
      return (section = <EditPayment />);
    case CodePages.SUCCESSFUL_OPERATION:
      return (section = <SuccessfulOperation />);
    case CodePages.CODE_SENDER_PAGE:
      return (section = <CodeSender />);
    case CodePages.SELECT_ACCOUNT_PAGE:
      return (section = <SelectAccount />);
    case CodePages.PERSONAL_IDENTIFICATION:
      return (section = <PersonalIdentification />);

    // Mantener siempre al final:
    case CodePages.INIT_PAGE:
    default:
      return (section = <InitPage changeStatelogin={changeStateLogin} />);

    /*
    case CodePages.TEST:
      return (section = (
        <WithdrawUSD lan={this.props.lan} getSection={this.getSection} params={this.state.params} />
      ));
 */
  }
}

export default withRouter(MainExchangePage);
