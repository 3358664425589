import React from 'react';
import { OpenMarketLogic } from './OpenMarket.module';
import ArrowBack from '../ArrowBack/ArrowBack'
import Formatter from '../../../classes/Formatter/Formatter';
import CodePages from '../../../classes/CodePages/CodePages';
import OffersCard from '../OffersCard/OffersCard';
import OffersCardSkeleton from '../OffersCardSkeleton/OffersCardSkeleton';

function OpenMarket() {
  // const classes = useStyles();
  const {
    values,
    show,
    setShow,
    offersSkeleton,
    loading,
    lan,
    theDataSet,
    processOffer,
    setGeneralValues
  } = OpenMarketLogic();

  const mainColor = 'c2';
  const enought = 'c6';
  const notEnought = 'c9';

  const filter_buttons = () => (
    <div className="col-12 p-0_5 flex">
      <div className="fit p-0_2">
        <div
          className={`fit p-0_5 pl-2 pr-2 border-radius-6 fs-0_6em fw-700 uppercase ${
            show === 'All' ? 'bg-c3 tc-c2-90' : 'bg-c2-70 tc-cw-90'
          } pointer`}
          onClick={() => setShow('All')}>
          {lan.Exchange.all}
        </div>
      </div>
      <div className="fit p-0_2">
        <div
          className={`fit p-0_5 pl-2 pr-2 border-radius-6 fs-0_6em fw-700 uppercase ${
            show === 'BUY' ? 'bg-c3 tc-c2-90' : 'bg-c2-70 tc-cw-90'
          } pointer`}
          onClick={() => setShow('BUY')}>
          {lan.Exchange.type_buy}
        </div>
      </div>
      <div className="fit p-0_2">
        <div
          className={`fit p-0_5 pl-2 pr-2 border-radius-6 fs-0_6em fw-700 uppercase ${
            show === 'SELL' ? 'bg-c3 tc-c2-90' : 'bg-c2-70 tc-cw-90'
          } pointer`}
          onClick={() => setShow('SELL')}>
          {lan.Exchange.type_sell}
        </div>
      </div>
    </div>
  );

  return (
    <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
      <div className="container">
        <div className="col-group">
          <div className="sticky-64 border-radius-tl-6 border-radius-tr-6 col-8 col-dt-4 col-offset-2 col-dt-offset-4 p-0 pt-0_2 bg-cw flex align-left">
          <ArrowBack />
          </div>

          <div className="sticky-64 col-8 col-dt-4 col-offset-2 col-dt-offset-4 p-2 pt-1 pt-m-0 pl-m-1 pr-m-1 pb-0 bg-cw">
            <div className="col-group pl-m-1 pr-m-1">
              <p className={`col-12 fs-1_6em fs-m-1_4em fw-500 tc-${mainColor} t-c`}>
                {lan.OpenMarket.OpenMarket}
              </p>

              <div className="col-12">
                {values.buy ? (
                  <div
                    className={`col-12 p-0_2 bg-cw border-radius-6 bc-c5-1 ${values.clickTimestampVerification}`}>
                    <div
                      className={`pb-0_2 fs-0_8em fw-400 t-c ${values.clickTimestampVerification}`}>
                      {lan.Exchange.current_usd}
                    </div>

                    <div
                      className={`fw-600 pb-0_2 fs-2em lh-1em t-c ${values.clickTimestampVerification}`}>
                      {values.usd}
                    </div>
                    <div
                      className={`pb-0_2 fs-0_8em fw-400 t-c ${values.clickTimestampVerification}`}>
                      {lan.Exchange.available} {values.availableUSD} USD
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {values.sell ? (
                  <div className="col-12 p-0_2 bg-cw border-radius-6 bc-c5-1 t-c">
                    <div className={`pb-0_2 fs-0_8em fw-400 ${values.clickTimestampVerification}`}>
                      {lan.Exchange.current_coins}
                    </div>
                    <div
                      className={` fw-600 pb-0_2 fs-2em lh-1em ${values.clickTimestampVerification}`}>
                      {values.egreenCoin}
                    </div>
                    <div className={`pb-0_2 fs-0_8em fw-400 ${values.clickTimestampVerification}`}>
                      {lan.Exchange.available}
                      <span className="fw-600"> {values.availableCoins} </span>
                      EGRN
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className="col-12">
                <div className="col-group p-1 p-m-0_5 pb-1 pt-0_5">
                  <div
                    className={`col-12 p-0_5 bc-c7-1 border-radius-6 ${values.clickTimestampVerification}`}>
                    {values.offerStatus ? (
                      <>
                        <div className="col-group p-0 m-0">
                          {/* <div className={`col-12 fs-1_2em fs-m-1em fw-600 uppercase tc-${mainColor} t-c`}>
                                                        Buy
                                                    </div> */}

                          <div className="col-4 col-mb-6 p-0_2">
                            <div className="bg-c2 p-0_2 border-radius-6">
                              <div className="pb-0_2 pt-0_2 fs-0_6em lh-1em fw-600 t-c tc-cw-90">
                                {lan.OpenMarket.no_coins}
                              </div>
                              <div className="open-sans pb-0_2 fs-2em lh-1em t-c tc-cw-90">
                                {values.offerData.amount}
                              </div>
                            </div>
                          </div>
                          <div className="col-4 col-mb-6 p-0_2">
                            <div className="bg-c2 p-0_2 border-radius-6">
                              <div className="pb-0_2 pt-0_2 fs-0_6em lh-1em fw-600 t-c tc-cw-90">
                                {lan.OpenMarket.coins_price}
                              </div>
                              <div className="open-sans pb-0_2 fs-2em lh-1em t-c tc-cw-90">
                                {values.offerData.price}
                              </div>
                            </div>
                            <div className="open-sans p-0_2 fs-0_6em lh-1em fw-600 t-c tc-cb" />
                          </div>
                          <div className="col-4 col-mb-12 p-0_2">
                            <div
                              className={`p-0_2 border-radius-6 bg-${
                                values.offerData.hasFunds ? enought : notEnought
                              }`}>
                              <div
                                className={`pb-0_2 pt-0_2 fs-0_6em lh-1em fw-600 t-c tc-${
                                  values.offerData.hasFunds ? 'cw' : 'c2'
                                }-90 uppercase`}>
                                {lan.OpenMarket.total_usd}
                              </div>
                              <div
                                className={`open-sans hidden pb-0_2 fs-2em lh-1em t-c tc-${
                                  values.offerData.hasFunds ? 'cw' : 'c2'
                                }-90`}
                                style={{ maxHeight: '38px' }}>
                                {Formatter.formatNumber(
                                  values.offerData.amount * values.offerData.price * 1.02,
                                  2
                                )}
                              </div>
                            </div>
                            <div className="open-sans p-0_2 fs-0_6em lh-1em fw-600 t-c tc-cb">
                              2% FEE
                            </div>
                          </div>
                        </div>

                        {values.offerData.hasFunds === false && (
                          <div className="fs-0_8em lh-1em fw-600 uppercase">
                            {values.buyable === 1 && (
                              <div
                                className={` pointer p-1 mt-0_5 mb-0_5 pr-2 pl-2 border-radius-24 bg-${notEnought} box-shadow tc-${mainColor} fs-1_2em t-c`}
                                onClick={() =>
                                  setGeneralValues((old_values) => ({
                                    ...old_values,
                                    section: CodePages.TRANSFER_USD_PAGE
                                  }))
                                }>
                                {lan.OpenMarket.transfer_needed}
                              </div>
                            )}
                            <div className="fs-1em lh-1em t-c">{lan.OpenMarket.not_enought}</div>
                          </div>
                        )}

                        {values.offerData.hasFunds === true && (
                          <div className="fs-0_8em lh-1em fw-600 uppercase">
                            {values.buyable === 2 && (
                              <div
                                className={` pointer p-1 mt-0_5 mb-0_5 pr-2 pl-2 border-radius-24 bg-c3 box-shadow tc-${mainColor} fs-1_2em t-c`}
                                onClick={() => processOffer()}>
                                {lan.OpenMarket.buy_order}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="col-12 pt-0_5 pb-0_5 fs-1em flex flex-wrap flex-center">
                        <span className=" uppercase tc-c5">{lan.OpenMarket.no_order_selected}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-8 col-dt-4 col-offset-2 col-dt-offset-4 p-2 pt-1 pt-m-0 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-bl-6 border-radius-br-6 bg-cw">
            {filter_buttons()}
            <div className="col-12 p-0 p-m-0">
              <div className={`flex flex-wrap p-0 mb-1 col-12 `}>
                {loading && offersSkeleton.map(() => <OffersCardSkeleton />)}
                {values.filteredTransactions &&
                  values.filteredTransactions.map((offer) => (
                    <OffersCard offer={offer} elDataSet={theDataSet} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenMarket;
