import React from 'react';
import CustomChart from '../Graphic/Graphic';
import { newOffersLogic } from './newOffers.module';
import OrderMaker from '../OrdersMaker/orderMaker';
import ArrowBack from '../ArrowBack/ArrowBack'
import CodePages from '../../../classes/CodePages/CodePages';

export default function NewOffers(props) {
  const { title, lan, setGeneralValues } = newOffersLogic(props);
  return (
    <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
      <div className="container">
        <div className="col-group">
          <div className="col-4 col-offset-4 p-2 pl-4 pr-4 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-6 bg-cw">
            <ArrowBack section={CodePages.EXCHANGE_PAGE} />
            <h1>{title}</h1>
            <CustomChart />
            <OrderMaker
              type={props.type}
              interfaceSelector={props.interfaceSelector}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
