import React from 'react';
import '../../../styles/index.css';
import '../../../styles/Exchange.css';
import '../../../styles/general-styles.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CodePages from '../../../classes/CodePages/CodePages';
import Wallet from '../Wallet/Wallet';
import OpenOrders from '../OpenOrders/OpenOrders';
import MyCurrentOrders from '../MyCurrentOrders/MyCurrentOrders';
import PersonalDetails from '../PersonalDetails/PersonalDetails';
import ArrowBack from '../ArrowBack/ArrowBack'
import { ExchangeLogic } from './Exchange.module';

function Exchange({ }) {
  const { lan, setGeneralValues } = ExchangeLogic();
  const mainColor = 'c2';
  return (
    <div className="pt-3 p-m-1 pb-4 pb-m-3">
      <div className="container">
        <div className="col-group">
          <div className="col-8 col-dt-4 col-offset-2 col-dt-offset-4 p-0 pt-0_2 border-radius-tl-6 border-radius-tr-6 bg-cw flex align-left">
          <ArrowBack section={CodePages.INIT_PAGE}/> 
          </div>
          <div className="col-8 col-dt-4 col-offset-2 col-dt-offset-4 p-2 pt-1 pt-m-0 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-bl-6 border-radius-br-6 bg-cw t-c">
            <h2 className={`fs-1_6em lh-1em tc-${mainColor} fw-500 pb-1`}>
              {lan.Exchange.your_xchange_account}
            </h2>
            <Wallet type="SELL" />
            <Wallet type="BUY" />
            <OpenOrders />
            <MyCurrentOrders />
            <PersonalDetails />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exchange;
