import { useEffect, useState, useContext } from 'react';
import Storage from '../../../classes/Storage/Storage';
import Formatter from '../../../classes/Formatter/Formatter';
import { loadStripe } from '@stripe/stripe-js';
import Fee from '../../../classes/Fee/Fee';
import { TransferToBuyService } from './transferToBuy.module';
import { useHistory } from 'react-router-dom';
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY_STRIPE);
import GeneralContext from '../../../context/GeneralContext';

const INTERFACE_DICTIONARY = {
  DEFAULT: 0,
  LOADER: 1,
  ERROR: 2,
  SUCCESS: 3
};

const TransferToBuyLogic = (props) => {
  const { lan, transaction, setGeneralValues } = useContext(GeneralContext);
  const { interfaceSelector: interfaceSelectorValue = INTERFACE_DICTIONARY.DEFAULT } = props;
  const [interfaceSelector, setInterfaceSelector] = useState(interfaceSelectorValue);
  const [values, setValues] = useState({
    amount: 0
  });
  const [disableButton, setDisableButton] = useState(true);
  const history = useHistory();

  const onChange = (e) => {
    e.preventDefault();
    const amount = Formatter.round(Number(e.target.value));
    setValues({ ...values, amount });
    setDisableButton(amount <= 0);
  };

  const theMessage = async () => {
    try {
      setInterfaceSelector(INTERFACE_DICTIONARY.LOADER);
      // ToDo: Re factor el servicio a checkout
      const transaction = await TransferToBuyService().create({
        amount_usd: values.amount,
        id_user: new Storage('id').getItem(),
        type: 'buy_usd'
      });
      const stripe = await stripePromise;

      const pay = Formatter.round(
        values.amount * process.env.REACT_APP_MONEY_FACTOR * Fee.MATH_USD_FEE,
        0
      );
      const sessionStripe = await TransferToBuyService().checkout({
        pay,
        offset: 'Transfer to buy',
        id_pretransaction: transaction.id
      });
      console.log(sessionStripe);
      await stripe.redirectToCheckout({
        sessionId: sessionStripe.id
      });
    } catch (error) {
      console.log({ error });
      setTimeout(() => {
        setInterfaceSelector(INTERFACE_DICTIONARY.ERROR);
      }, 1000);
    }
  };

  useEffect(() => {
    if (transaction) {
      console.log({ transaction });
      const hash = transaction.hash || 0;
      setInterfaceSelector(INTERFACE_DICTIONARY.LOADER);
      const checkPayFunction = async () => {
        const sessionStripe = await TransferToBuyService().checkPay({
          idPretransaction: transaction._id,
          lan: lan.locale
        });
        console.log({ sessionStripe });
        setInterfaceSelector(
          sessionStripe.payment_status ? INTERFACE_DICTIONARY.SUCCESS : INTERFACE_DICTIONARY.ERROR
        );
      };

      checkPayFunction()
        .then(() => {
          setGeneralValues((old_values) => ({ ...old_values, transaction: null }));
          setValues({ ...values, hash });
        })
        .catch(() => setInterfaceSelector(INTERFACE_DICTIONARY.ERROR));
    }
  }, []);

  return {
    interfaceSelector,
    values,
    disableButton,
    lan,
    history,
    INTERFACE_DICTIONARY,
    setGeneralValues,
    theMessage,
    onChange
  };
};

export default TransferToBuyLogic;
