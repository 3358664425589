import React from 'react';
import Loader from '../../../components/Exchange/Loader/Loader';
import ExchangePage from '../../../components/Exchange/ExchangePage';
import CodePages from '../../../classes/CodePages/CodePages';
import Error from '../../../components/Exchange/Error/Error';
import { CheckoutLogic } from './Checkout.module';

const Checkout = ({ page = 1, session_id, lan }) => {
  const { section, SECTION_DICTIONARY } = CheckoutLogic({
    page,
    session_id
  });

  return (
    <>
      {section === SECTION_DICTIONARY.LOADER && <Loader />}
      {section === SECTION_DICTIONARY.ERROR && <Error />}
      {section === SECTION_DICTIONARY.SUCCESS_USD && (
        <ExchangePage page={CodePages.TRANSFER_USD_PAGE} />
      )}
    </>
  );
};
export default Checkout;
