import { useEffect, useState, useContext } from 'react';
import Graphic from '../../../classes/Graphic/Graphic';
import GeneralContext from '../../../context/GeneralContext';

const GraphicsLogic = ({ foreColor, GraphicsService }) => {
  const { lan } = useContext(GeneralContext);
  const _options = {
    chart: {
      toolbar: {
        tools: {
          download: false,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      },
      id: 'basic-bar',
      background: 'transparent'
    },
    stroke: {
      width: 3,
      curve: 'smooth'
    },
    // colors: ['#06419b'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#5CC882'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100]
      }
    },
    markers: {
      size: 4,
      colors: ['#FFA41B'],
      strokeColors: '#fff',
      strokeWidth: 0,
      hover: {
        size: 7
      }
    },

    xaxis: {
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,
      seriesName: undefined,
      logarithmic: false,
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        // align: 'right',
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => `${value}`
      }
    }
  };
  _options.chart.foreColor = foreColor;
  _options.chart.foreColor = foreColor;
  _options.yaxis.axisBorder = { show: true, color: foreColor };
  _options.grid = {
    show: true,
    borderColor: foreColor,
    strokeDashArray: 2,
    opacity: 0.5
  };
  const [options, setOptions] = useState(_options);
  const [seriesValue, setSeries] = useState([]);
  const [actualValue, setActualValue] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const graphics = await GraphicsService().getData();
      const response = Graphic.processData(graphics);
      await setActualValue(response.actualValue);
      await setSeries(response.seriesValue);
      await setOptions(response.options);
    }
    fetchData();
  }, []);

  return {
    options,
    seriesValue,
    actualValue,
    lan
  };
};

export default GraphicsLogic;
