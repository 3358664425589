import React from "react";
import { StripeConnectLogic } from "./StripeConnect.module";

const StripeConnect = (props) => {
  const { values, stripeConnect } = StripeConnectLogic(props);

  return (
    <div
      className={values.activeClass}
      onClick={() => (props.disabled ? null : stripeConnect())}
    >
      Stripe Connect
    </div>
  );
};

export default StripeConnect;
