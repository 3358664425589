import API from '../../classes/API/API';
import URL from '../../classes/URL/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/Error/CustomError';

const SessionService = () => {
  const getSessionById = async (id) => {
    const api = new API(`${URL.URL_SERVER}/sessions/${id}`);
    const http_response = await api.get();
    const response = new Response(http_response);
    if (!response.hasError()) {
      return response.wasSuccessfull().getSingle();
    }
    return new CustomError(response.getData());
  };
  return { getSessionById };
};

export default SessionService;
