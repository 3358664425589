import React from 'react';
import MatiButtonLogic from './MatiButton.logic';

const MatiButton = ({ color, props }) => {
  const { metaDataValues, ref } = MatiButtonLogic(props);

  return (
    <div className="col-12 bg-ct p-0_5 mb-1 t-c tc-cw fs-0_6em block">
      <mati-button
        ref={ref}
        clientId={process.env.REACT_APP_MATI_ID}
        flowId={process.env.REACT_APP_FLOW_ID}
        color={color}
        metadata={JSON.stringify(metaDataValues)}
      />
    </div>
  );
};

export default MatiButton;
