import Session from '../../../classes/Session/Session';
import { auth } from './privateRoute.service';
import Storage from '../../../classes/Storage/Storage';

const PrivateRouteLogic = () => {
  async function check() {
    try {
      const isTokenStored = new Storage('token').getItem();
      const authInApi = await auth();
      if (!isTokenStored || authInApi.status === 500) {
        await Session.logout();
        return false;
      }
      return true;
    } catch (error) {
      await Session.logout();
      return false;
    }
  }

  return { check };
};
export default PrivateRouteLogic;
