import { useContext, useEffect } from 'react';
import GeneralContext from '../../../context/GeneralContext';

const LoaderLogic = () => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  useEffect(() => {
    //document.body.style.backgroundImage = 'linear-gradient(to right, #FFFFFF, #FFFFFF)';
    window.scrollTo({ top: 0 });
  }, []);
  return {
    lan,
    setGeneralValues
  };
};
export default LoaderLogic;
