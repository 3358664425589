import API from "../../../classes/API/API";
import Response from "../../../classes/Response/Response";

const url = `${process.env.REACT_APP_API}/transactions`;

async function create(data) {
  const api = new API(`${url}`);
  const response = new Response(await api.post(data))
    .wasSuccessfull()
    .getSingle();
  console.log({ response });
  return response;
}

async function getById(id) {
  const api = new API(`${url}/${id}`);
  const response = new Response(await api.get()).wasSuccessfull().getSingle();
  console.log({ response });
  return response;
}

async function get(id_user) {
  const api = new API(`${url}?filter={"id_user":"${id_user}"}`);
  const response = new Response(await api.get()).wasSuccessfull().getMultiple();
  console.log({ response });
  return response;
}

export default { create, getById, get };