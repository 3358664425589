import { useState, useEffect, useContext } from 'react';
import CodePages from '../../../classes/CodePages/CodePages';
import MyCurrentOrdersService from '../MyCurrentOrders/MyCurrentOrders.service';
import OpenOrdersService from '../OpenOrders/OpenOrders.service';
import WalletService from '../Wallet/Wallet.service';
import ExchangeService from './Exchange.service';
import GeneralContext from '../../../context/GeneralContext';

const ExchangeLogic = () => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  const [values, setValues] = useState({ user: {} });

  const loadTransactionUser = async () => {
    fetchData();
  };

  const fetchData = async () => {
    const user = await ExchangeService().getData();
    const wallet = await WalletService().getData();
    const has_bank_data = user.hasBankData();
    const isChilean = user.isChilean();
    const { egreenCoin, usd, availableCoins, availableUSD } = wallet;
    const transactions = await OpenOrdersService().getOffers();
    const offersOpen = await MyCurrentOrdersService().getOffers();
    setValues({
      ...values,
      has_bank_data,
      isChilean,
      egreenCoin,
      usd,
      availableCoins,
      availableUSD,
      allTransaction: transactions.resources ? transactions.resources : [],
      allTransactionUser: offersOpen,
      user
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    values,
    lan,
    loadTransactionUser,
    setGeneralValues
  };
};
export default ExchangeLogic;
