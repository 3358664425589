import React, { useState } from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { MyCurrentOrdersLogic, MyCurrentOrdersService } from './MyCurrentOrders.module';
import OffersCard from '../OffersCard/OffersCard';

function MyCurrentOrders({ }) {
  // const classes = useStyles();
  const { values, show, setShow, reload, lan } = MyCurrentOrdersLogic(
    MyCurrentOrdersService
  );
  const [activeClass, setActiveClass] = useState(false);
  const mainColor = 'c2';

  const filter_buttons = () => (
    <div className="col-12 p-0_5 flex">
      <div className="fit p-0_2">
        <div
          className={`fit p-0_5 pl-2 pr-2 border-radius-6 fs-0_6em fw-700 uppercase ${show === 'All' ? 'bg-c3 tc-c2-90' : 'bg-c2-70 tc-cw-90'
            } pointer`}
          onClick={() => setShow('All')}>
          {lan.Exchange.all}
        </div>
      </div>
      <div className="fit p-0_2">
        <div
          className={`fit p-0_5 pl-2 pr-2 border-radius-6 fs-0_6em fw-700 uppercase ${show === 'BUY' ? 'bg-c3 tc-c2-90' : 'bg-c2-70 tc-cw-90'
            } pointer`}
          onClick={() => setShow('BUY')}>
          {lan.Exchange.type_buy}
        </div>
      </div>
      <div className="fit p-0_2">
        <div
          className={`fit p-0_5 pl-2 pr-2 border-radius-6 fs-0_6em fw-700 uppercase ${show === 'SELL' ? 'bg-c3 tc-c2-90' : 'bg-c2-70 tc-cw-90'
            } pointer`}
          onClick={() => setShow('SELL')}>
          {lan.Exchange.type_sell}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`col-12 bg-${mainColor} p-0_5 border-radius-12 mb-1 box-shadow`}>
      <div
        className="col-12 flex flex-space-between align-item-center pl-1 cursor-pointer"
        onClick={() => setActiveClass(!activeClass)}>
        <span className="tc-cw fs-1_2em fs-m-1em  lh-1_2em t-m-l">
          {lan.Exchange.my_current_orders}
        </span>
        <ArrowRightIcon
          className={`fc-cw box-1_4em transition ${!activeClass ? '' : 'rotate-down'}`}
        />
      </div>
      <div
        testing="true"
        className={`col-12 p-0_5 border-radius-bl-6 border-radius-br-6 bg-cw mh-18 vscroll border-radius-6  ${!activeClass ? 'none' : ''
          }`}>
        {filter_buttons()}
        <div className="col-12 p-0 p-m-0">
          <div className={`flex flex-wrap p-0 mb-1 col-12 `}>
            {values.filteredTransactions &&
              values.filteredTransactions.map((offer) => (
                <OffersCard offer={offer} canDelete reload={reload} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCurrentOrders;
