import React from 'react';
import { Form, Input } from 'antd';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CodeSenderLogic from './CodeSender.logic';
import CodePages from '../../../classes/CodePages/CodePages';
import Loader from '../Loader/Loader';
import ArrowBack from '../ArrowBack/ArrowBack'

const FormItem = Form.Item;
const CodeSender = (props) => {
  const { getFieldDecorator } = props.form;
  const { values, code, lan, setGeneralValues, confirmProcess, onChange } = CodeSenderLogic(props);

  return (
    <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
      <div className="container">
        <div className="col-group">
          <div className="col-4 col-offset-4 p-0 pt-0_2 border-radius-tl-6 border-radius-tr-6 bg-c2 flex align-left">
          <ArrowBack/>
          </div>

          <div className="col-4 col-offset-4 p-2 pt-1 pt-m-0 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-bl-6 border-radius-br-6 bg-c2">
            <p className="fs-2em fs-m-1_6em lh-1em p-1 pt-m-0_5 pb-0 fw-500 tc-cw t-c">
              {lan.Exchange.code_sent}
            </p>
            <p className="fs-1em lh-1em p-1 pt-0 pb-1 t-c m-0 tc-cw">{lan.Exchange.insert_code}</p>

            <Form name="SendCode" className="col-group">
              <div className="col-6 col-offset-3 col-mb-8 col-mb-offset-2">
                <FormItem name="password" className="">
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Please, enter the code'
                      }
                    ]
                  })(
                    <Input
                      className="tc-c6 fs-1em lh-2em bc-c6-2 bg-cw border-radius-12"
                      onChange={onChange}
                      value={code}
                    />
                  )}
                </FormItem>
              </div>

              {values.failCode ? (
                <div className="col-6 col-offset-3 col-mb-8 col-mb-offset-2">
                  <p className="fs-1em lh-1em p-1 pt-0 pb-1 t-c m-0 tc-cw">{values.message}</p>
                </div>
              ) : (
                ''
              )}
              <div className="col-6 col-offset-3 col-mb-8 col-mb-offset-2 p-0 pt-1">
                {values.verifyCode ? (
                  <div
                    className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-c3 tc-c2 fs-1em lh-m-1em mb-1"
                    onClick={() => confirmProcess()}>
                    {lan.Exchange.verify_code}
                  </div>
                ) : (
                  <div
                    className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-ci tc-c7 fs-1em lh-m-1em mb-1"
                    disabled>
                    {lan.Exchange.verify_code}
                  </div>
                )}
                {values.loading && (
                  <div className="col-12 pt-2 mt-3 pb-3 mih-16 t-c">
                    <Loader />
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
const SendCode = Form.create()(CodeSender);
export default SendCode;
