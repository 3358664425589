import moment from 'moment';
import Formatter from '../Formatter/Formatter';

class Offer {
  constructor({ _id, id, date, type, price, amount, status, description, user }) {
    this.id = id || _id;
    this.date = moment(date).format('DD-MM-YYYY');
    this.type = type;
    this.price = Formatter.formatNumber(price, 2);
    this.amount = Formatter.formatNumber(amount, 2);
    this.status = status;
    this.user = user;
    this.description = description;
  }

  toCreate({ opposite = false }) {
    return {
      price: this.price,
      amount: this.amount,
      type: opposite ? this.opposite() : this.type,
      description: this.description,
      id_user: this.user
    };
  }

  toProcess() {
    return {
      id_offer: this.id,
      id_user: this.user
    };
  }

  toConfirm(code) {
    return {
      id_offer: this.id,
      id_user: this.user,
      code
    };
  }

  opposite() {
    return this.type === 'BUY' ? 'SELL' : 'BUY';
  }

  isBuy() {
    return this.type === 'BUY';
  }

  isSell() {
    return this.type === 'SELL';
  }
}

export default Offer;
