import { createContext } from 'react';

const Context = createContext({
  language: 'en',
  section: 1,
  params: null,
  transaction: null,
  callbackFunction: null,
  setGeneralValues: (values) => {}
});

export default Context;
