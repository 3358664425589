class Formatter {
  static formatNumber(number = 0, decimals = 0) {
    let converted_number = number;
    if (typeof number === 'string' && number.length === 0) {
      converted_number = 0;
    }
    converted_number = converted_number.toString();
    const [integer = 0] = converted_number.split('.');
    if (decimals > 0) {
      return Number.parseFloat(converted_number).toFixed(decimals);
    }
    return `${integer}`;
  }

  static hasDecimals(number) {
    return !Number.isInteger(number);
  }

  static validDecimals(number) {
    return /^[\d]*\.?[\d]{0,2}$/.test(this.formatDecimals(number));
  }

  static formatDecimals(number) {
    if (number.length <= 0) {
      return '0';
    }

    number -= 0;

    return number.toString();
  }

  static round(number, fixed = 2) {
    return !number ? 0 : Number(number.toFixed(fixed));
  }
}

export default Formatter;
