import { useContext, useEffect, useState } from 'react';
import WalletService from '../Wallet/Wallet.service';
import Formatter from '../../../classes/Formatter/Formatter';
import NewOffersService from '../NewOffers/newOffers.service';
import CodePages from '../../../classes/CodePages/CodePages';
import Offer from '../../../classes/Offer/Offer';
import Storage from '../../../classes/Storage/Storage';
import Fee from '../../../classes/Fee/Fee';
import GeneralContext from '../../../context/GeneralContext';

const OrderMakerLogic = (props) => {
  const { lan, setGeneralValues, params = {} } = useContext(GeneralContext);
  const { interfaceSelector: interfaceSelectorValue = 0 } = params;
  const [interfaceSelector] = useState(interfaceSelectorValue);
  const [title1Currency, setTitle1Currency] = useState(
    props.type === 'BUY' ? lan.Exchange.current_balance : lan.Exchange.current_coins
  );
  const [numberTitle1, setNumberTitle1] = useState(0);
  const [available, setAvailable] = useState(0);
  const [title2, setTitle2] = useState(
    `${lan.Exchange.available} ${available} ${props.type === 'BUY' ? 'USD' : 'EGRN'}`
  );
  const [coinsText, setCoinsText] = useState(
    props.type === 'BUY' ? lan.Exchange.number_coins : lan.Exchange.sell_coins
  );
  const [values, setValues] = useState({
    fee: Fee.FEE,
    price: 0,
    amount: 0,
    coins: 0,
    fee_amount: 0
  });
  const [DisableButton, setDisableButton] = useState(true);
  const [buttonMessage, setButtonMessage] = useState(lan.Exchange.put_order);
  const [buttonClassName, setButtonClassName] = useState('button-putOrder');
  const [wallet, setWallet] = useState({
    usd: 0,
    egreenCoin: 0,
    availableUSD: 0,
    availableCoins: 0
  });
  /*
  Anteriormente putOrder:
    0 = Cargar la interfaz inicial
    1 = Cargar loader
    2 = Error
    3 = Compra satisfactoria
  */

  useEffect(() => {
    async function fetchData() {
      const response = await WalletService().getData();
      setWallet(response);
      const _available = props.type === 'BUY' ? +response.availableUSD : +response.availableCoins;
      setNumberTitle1(props.type === 'BUY' ? +response.usd : +response.egreenCoin);
      setAvailable(_available);
      setTitle2(`${lan.Exchange.available} ${_available} ${props.type === 'BUY' ? 'USD' : 'EGRN'}`);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setTitle1Currency(
      props.type === 'BUY' ? lan.Exchange.current_balance : lan.Exchange.current_coins
    );
    setTitle2(`${lan.Exchange.available} ${available} ${props.type === 'BUY' ? 'USD' : 'EGRN'}`);
    setButtonMessage(lan.Exchange.put_order);
    setCoinsText(props.type === 'BUY' ? lan.Exchange.number_coins : lan.Exchange.sell_coins);
  }, [props]);

  const onChange = (id) => (e) => {
    e.preventDefault();
    const { value } = e.target;
    setValues((values) => ({
      ...values,
      [`${id}`]: value
    }));
  };

  useEffect(() => {
    setValues({
      ...values,
      fee_amount: Formatter.round(values.coins * values.price * values.fee, 2),
      amount: Formatter.round(values.coins * values.price + values.fee_amount, 2)
    });
    checkStatus();
  }, [values.coins, values.price, values.amount, DisableButton]);

  const checkStatus = () => {
    switch (props.type) {
      case 'BUY':
        const isEnoughCurrency = values.amount <= +wallet.availableUSD;
        setDisableButton(values.amount <= 1 && isEnoughCurrency);
        setButtonMessage(!isEnoughCurrency ? 'Transfer Needed' : 'Put Order');
        setButtonClassName(!isEnoughCurrency ? 'button-transferNeeded' : 'button-putOrder');
        break;
      case 'SELL':
        setDisableButton(values.amount <= 0);
        break;
      default:
        break;
    }
  };

  const onClick = async () => {
    const offer = new Offer({
      price: values.price,
      amount: values.coins,
      description: `${props.type} OFFER`,
      user: new Storage('id').getItem(),
      type: props.type
    });
    const newOffer = await NewOffersService().createOffer(offer);
    const processedOffer = await NewOffersService().processOffer(newOffer);

    if (typeof processedOffer !== 'CustomError') {
      setGeneralValues((old_values) => ({
        ...old_values,
        section: CodePages.CODE_SENDER_PAGE,
        params: newOffer,
        callBackFunction: confirmProcessOrder
      }));
    }
  };

  const confirmProcessOrder = async ({ offer, code }) => {
    await NewOffersService().confirmProcessOffer(offer, code);
    // TODO: Capturar error
    setGeneralValues((old_values) => ({
      ...old_values,
      section: CodePages.CREATE_BUY_OFFER_PAGE,
      params: { interfaceSelector: 3 },
      callBackFunction: null
    }));
  };

  return {
    title1Currency,
    numberTitle1,
    title2,
    values,
    DisableButton,
    buttonMessage,
    buttonClassName,
    interfaceSelector,
    coinsText,
    lan,
    setGeneralValues,
    onClick,
    onChange
  };
};

export default OrderMakerLogic;
