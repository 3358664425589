import API from '../../../classes/API/API';
import URL from '../../../classes/URL/URL';
import Response from '../../../classes/Response/Response';
import CustomError from '../../../classes/Error/CustomError';

const GraphicsService = () => {
  const getData = async () => {
    const api = new API(`${URL.URL_SERVER}/graphics`);
    const http_response = await api.get();
    const response = new Response(http_response);
    if (!response.hasError()) {
      return response.wasSuccessfull().getSingle();
    }
    return new CustomError(response.getData());
  };

  return {
    getData
  };
};
export default GraphicsService;
