class CodePages {
  static get SUCCESSFUL_OPERATION() {
    return 3083;
  }
  static get TEST() {
    return 123;
  }
  static get EXCHANGE_PAGE() {
    return 2061;
  }

  static get MATI_PAGE() {
    return 3021;
  }

  static get MATI_PENDING() {
    return 3022;
  }

  static get INIT_PAGE() {
    return 1020;
  }

  static get SELECT_ACCOUNT_PAGE() {
    return 1030;
  }

  static get CODE_SENDER_PAGE() {
    return 9010;
  }

  static get CREATE_BUY_OFFER_PAGE() {
    return 2091;
  }

  static get CREATE_SELL_OFFER_PAGE() {
    return 2101;
  }

  static get TRANSFER_USD_PAGE() {
    return 2121;
  }

  static get WITHDRAW_PAGE() {
    return 2111;
  }

  static get ALL_OFFERS() {
    return 20812;
  }

  static get SETUP_ACCOUNT_BANK_DATA() {
    return 2012;
  }

  static get INDEX_OFFSET_FOR_REWARDS() {
    return 2;
  }

  static get PROJECTS_OFFSET_FOR_REWARDS() {
    return 3;
  }

  static get SELECTED_PROJECT_OFFSET_FOR_REWARDS() {
    return 4;
  }

  static get DETAIL_PROJECT_OFFSET_FOR_REWARDS() {
    return 5;
  }

  static get PERSONAL_IDENTIFICATION() {
    return 3010;
  }
}

export default CodePages;
