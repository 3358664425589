import React, { Component } from 'react';

class GeneralError extends Component {
  async componentDidMount() {
    document.title = 'eGreen | Error';
    document.body.style.backgroundImage = 'linear-gradient(to right, #FFFFFF, #FFFFFF)';
    // "linear-gradient(to right, #0FA9C9, #5CC882)";
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <div className="col-group">
        <div className="col-8 col-offset-2">
          <div className="montse col-12 fs-2em fs-m-1_6em fw-500 t-c-m t-c mb-2 mt-2 color3">
            OOPS!! we are sorry for the inconvenience.
          </div>
          <div className="col-5 col-offset-1 col-md-12 mb-2">
            <div className="montse col-12 fs-1_2em color3 p-1 pl-2 mt-1 fw-500 mt-2">
              We are sorry for the inconvenience, we have a problem on our servers for this
              operation.
            </div>
          </div>
          <div className="col-5 col-offset-1 mb-1">
            <img
              src="/svg/error-404.svg"
              width="80%"
              className="w-m-100"
              alt={'error'}
              style={{ display: 'block', margin: 'auto' }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default GeneralError;
