import Storage from "../Storage/Storage";

class Session {
  static logout() {
    const language = new Storage("lan").getItem();
    const cookies = new Storage("cookies").getItem();
    new Storage().removeAllItems();
    new Storage("lan", language).setItem();
    new Storage("cookies", cookies).setItem();
  }
}

export default Session;
