import React from 'react';
import Chart from 'react-apexcharts';
import { GraphicsLogic, GraphicsService, useStyles } from './Graphic.module';

function CustomChart({ series, customOptions, customClass, foreColor }) {
  const { options, seriesValue, actualValue, lan } = GraphicsLogic({
    series,
    customOptions,
    customClass,
    foreColor,
    GraphicsService
  });

  const classes = useStyles();

  return (
    <>
      <div className="col-12 t-c mb-1 flex flex-center align-item-center">
        <span className={classes.mainText}>
          {lan.Exchange.actual_value} {actualValue} (USD)
        </span>
        <span className="fs-1_2em tc-c2" />
      </div>
      <div className={`col-12 bg-ct p-0_5 mb-1 tc-cw fs-0_6em block ${customClass}`}>
        <Chart options={options} series={seriesValue} type="line" width="100%" />
      </div>
    </>
  );
}

export default CustomChart;
