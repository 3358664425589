import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import GA4React from 'ga-4-react';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import Routes from './routes/index';
import * as serviceWorker from './serviceWorker';

const ga4react = new GA4React('');

(async (_) => {
  await ga4react
    .initialize()
    .then((res) => console.log('Analytics Success.'))
    .catch((err) => console.log('Analytics Failure.'))
    .finally(() => {
      ReactDOM.render(<Routes />, document.getElementById('root'));
    });
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
