import API from '../../../classes/API/API';
import Response from '../../../classes/Response/Response';
import Stripe from '../../../classes/Stripe/Stripe';

const StripeConnectService = () => {
  const createStripeAccount = async (id_user) => {
    const api = new API(`${process.env.REACT_APP_API}/stripe`);
    const response = new Response(await api.post({ id_user })).wasSuccessfull().getSingle();
    const stripe = new Stripe(response);
    console.log(stripe.url);
    return stripe.url;
  };

  const getStripeAccount = async (id_account) => {
    const api = new API(`${process.env.REACT_APP_API}/stripe/${id_account}`);
    const response = new Response(await api.get()).getSingle();
    console.log({ response });
    const stripe = new Stripe(response);
    console.log(stripe.url);
    return stripe.url;
  };

  return {
    createStripeAccount,
    getStripeAccount
  };
};

export default StripeConnectService;
