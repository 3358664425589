import API from '../../../classes/API/API';
import Response from '../../../classes/Response/Response';

const url = `${process.env.REACT_APP_API}`;

const TransferToBuyService = () => {
  async function checkout(data) {
    const api = new API(`${url}/create-checkout-session`);
    const response = new Response(await api.post(data)).wasSuccessfull().getSingle();
    return response;
  }

  async function checkPay(data) {
    const api = new API(`${url}/check-pay-session`);
    const response = new Response(await api.post(data)).wasSuccessfull().getSingle();
    return response;
  }

  async function create(data) {
    const api = new API(`${url}/transactions`);
    const response = new Response(await api.post(data)).wasSuccessfull().getSingle();
    return response;
  }

  return {
    checkPay,
    checkout,
    create
  };
};

export default TransferToBuyService;
