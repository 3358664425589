import API from '../../../classes/API/API';
import Response from '../../../classes/Response/Response';
import Storage from '../../../classes/Storage/Storage';

const url = `${process.env.REACT_APP_API}`;

const MyCurrentOrdersService = () => {
  const getOffers = async () => {
    const id = new Storage('id').getItem();
    const status = ['open'];
    const filter = id ? { status, ne_user: id } : { status };
    const endpoint = `${url}/users/${id}/offers?filter=${JSON.stringify(filter)}`;
    const api = new API(endpoint);
    const response = new Response(await api.get(id));
    return response.wasSuccessfull().getSingle();
  };

  return {
    getOffers
  };
};
export default MyCurrentOrdersService;
