import Storage from '../Storage/Storage';
const axios = require('axios');

class API {
  constructor(url, options = {}) {
    const storage = new Storage('token');
    this.url = url;
    this.options = options;
    this.config = {
      headers: {
        Authorization: `Bearer ${storage.getItem()}`
      }
    };
  }

  async post(data) {
    try {
      return await axios.post(this.url, data, this.config);
    } catch (error) {
      return this.error(error);
    }
  }

  async get() {
    try {
      return await axios.get(this.url, this.config);
    } catch (error) {
      return this.error(error);
    }
  }

  async delete() {
    try {
      return await axios.delete(this.url, this.config);
    } catch (error) {
      return this.error(error);
    }
  }

  async put(data) {
    try {
      return await axios.put(this.url, data, this.config);
    } catch (error) {
      return this.error(error);
    }
  }

  error(error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export default API;
