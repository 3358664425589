import React from 'react';
import { Form, Input, Select, DatePicker } from 'antd';
import moment from 'moment/moment';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { PersonalIdentificationLogic } from './PersonalIdentification.module';
import CodePages from '../../../classes/CodePages/CodePages';
import ArrowBack from '../ArrowBack/ArrowBack'

const { Option } = Select;
const FormItem = Form.Item;

function PersonalIdentification(props) {
  const { form } = props;
  const dateFormat = 'DD/MM/YYYY';
  const {
    values,
    flags,
    verifyPersonalIdentification,
    lan,
    updateData,
    onChange,
    processDate,
    disabledDate,
    setGeneralValues
  } = PersonalIdentificationLogic();

  const { getFieldDecorator } = form;

  return (
    <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
      <div className="container">
        <div className="col-group">
          <div className="col-4 col-offset-4 p-0 pt-0_2 border-radius-tl-6 border-radius-tr-6 bg-cw flex align-left">
            <ArrowBack section={CodePages.INIT_PAGE}/>
          </div>

          <div className="col-4 col-offset-4 p-2 pt-1 pt-m-0 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-bl-6 border-radius-br-6 bg-cw">
            <div className="col-12 t-c fs-1_8em fs-m-1_4em lh-1em fw-500 tc-c1 mb-1">
              {lan.PersonalIdentification.tittle}
            </div>
            <Form name="createAccouut" className="col-group">
              <div className="col-6 col-mb-6 p-0_2">
                <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-1 tc-ci uppercase t-l">
                  {lan.PersonalIdentification.name}
                </div>
                <Input value={values.name} disabled />
              </div>
              <div className="col-6 col-mb-6 p-0_2">
                <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-1 tc-ci uppercase t-l">
                  {lan.PersonalIdentification.last_name}
                </div>
                <Input value={values.lastname} disabled />
              </div>

              <div className="col-12 col-mb-12 p-0_2 mt-1 mb-m-0 pl-1 pr-2 fs-0_8em t-l uppercase">
                {lan.PersonalIdentification.birth_date}
              </div>
              <div className="col-12 col-mb-12 p-0_2 p-m-0_2 border-radius-3 bc-c7-1 pb-0_5">
                <DatePicker
                  name="date"
                  className="col-12 p-0"
                  style={{ width: '100%' }}
                  defaultPickerValue={moment().subtract(18, 'years')}
                  format={dateFormat}
                  disabledDate={disabledDate}
                  onChange={processDate}
                />
              </div>

              <div className="col-12 col-mb-12 p-0_2 mt-1 mb-m-0 pl-1 pr-2 fs-0_8em t-l uppercase">
                {lan.PersonalIdentification.address}
              </div>
              <div className="col-12 col-mb-12 p-0_2 p-m-0_2 border-radius-3 bc-c7-1 pb-0_5">
                <FormItem name="address" className="col-12 col-mb-12 p-0_2 mb-0">
                  {getFieldDecorator('postalAddress', {
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]
                  })(
                    <Input
                      placeholder={lan.PersonalIdentification.address}
                      className="input-text"
                      onChange={onChange}
                    />
                  )}
                </FormItem>
                <FormItem name="unit" className="col-12 col-mb-12 p-0_2 mb-0">
                  {getFieldDecorator('unit', {
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]
                  })(
                    <Input
                      placeholder={lan.PersonalIdentification.unit_number}
                      className="input-text"
                      onChange={onChange}
                    />
                  )}
                </FormItem>
                <FormItem name="locality" className="col-12 col-mb-12 p-0_2 mb-0">
                  {getFieldDecorator('locality', {
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]
                  })(
                    <Input
                      placeholder={lan.PersonalIdentification.city}
                      className="input-text"
                      onChange={onChange}
                    />
                  )}
                </FormItem>
                <div className="col-12 col-mb-12 p-0 flex align-item-center">
                  <FormItem name="postalCode" className="col-6 col-mb-6 p-0_2 mb-0">
                    {getFieldDecorator('postalCode', {
                      rules: [
                        {
                          required: true,
                          message: 'Required'
                        }
                      ]
                    })(
                      <Input
                        placeholder={lan.PersonalIdentification.postal_code}
                        className="input-text"
                        onChange={onChange}
                      />
                    )}
                  </FormItem>
                  <FormItem name="country" className="col-6 col-mb-6 p-0_2 mb-0">
                    <Select
                      showSearch
                      placeholder={lan.PersonalIdentification.country}
                      className="col-12 p-0"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) => {
                        const { children } = option.props;

                        const value = Array.isArray(children)
                          ? children[children.length - 1]
                          : children;
                        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      }}>
                      {flags}
                    </Select>
                  </FormItem>
                </div>
              </div>
              <div className="col-12 col-mb-12 p-0_2 mt-1 mb-m-0 pl-1 pr-2 fs-0_8em t-l uppercase">
                {lan.PersonalIdentification.why}
              </div>
              <div className="col-12 col-mb-12 p-0_2 p-m-0_2 border-radius-3 bc-c7-1 bg-c8 pb-0_5">
                <FormItem name="whyEgreen" className="col-12 col-mb-12 p-0_2 mb-0">
                  <Select
                    placeholder={lan.PersonalIdentification.select_option}
                    className="col-12 p-0"
                    onChange={onChange}>
                    <Option value="whyEgreen-Invest for profit">
                      {lan.PersonalIdentification.why_1}
                    </Option>
                    <Option value="whyEgreen-Environmental impact">
                      {lan.PersonalIdentification.why_2}
                    </Option>
                    <Option value="whyEgreen-Help green projects">
                      {lan.PersonalIdentification.why_3}
                    </Option>
                  </Select>
                </FormItem>
              </div>
              <div className="col-12 col-mb-12 p-0_2 mt-1 mb-m-0 pl-1 pr-2 fs-0_8em t-l uppercase">
                {lan.PersonalIdentification.source_income}
              </div>
              <div className="col-12 col-mb-12 p-0_2 p-m-0_2 border-radius-3 bc-c7-1 bg-c8 pb-0_5">
                <FormItem name="sourceIncome" className="col-12 col-mb-12 p-0_2 mb-0">
                  <Select
                    placeholder={lan.PersonalIdentification.select_option}
                    className="col-12 p-0"
                    onChange={onChange}>
                    <Option value="sourceIncome-My job income">
                      {lan.PersonalIdentification.source_income_1}
                    </Option>
                    <Option value="sourceIncome-Real estates">
                      {lan.PersonalIdentification.source_income_2}
                    </Option>
                    <Option value="sourceIncome-Investment Family">
                      {lan.PersonalIdentification.source_income_3}
                    </Option>
                  </Select>
                </FormItem>
              </div>
              <div className="col-12 col-mb-12 p-0_2 mt-1 mb-m-0 pl-1 pr-2 fs-0_8em t-l uppercase">
                {lan.PersonalIdentification.employment_status}
              </div>
              <div className="col-12 col-mb-12 p-0_2 p-m-0_2 border-radius-3 bc-c7-1 bg-c8 pb-0_5">
                <FormItem name="employmentStatus" className="col-12 col-mb-12 p-0_2 mb-0">
                  <Select
                    placeholder={lan.PersonalIdentification.select_option}
                    className="col-12 p-0"
                    onChange={onChange}>
                    <Option value="employmentStatus-Independent contractor">
                      {lan.PersonalIdentification.employment_status_1}
                    </Option>
                    <Option value="employmentStatus-Payroll">
                      {lan.PersonalIdentification.employment_status_2}
                    </Option>
                    <Option value="employmentStatus-Student">
                      {lan.PersonalIdentification.employment_status_3}
                    </Option>
                    <Option value="employmentStatus-Unemployment">
                      {lan.PersonalIdentification.employment_status_4}
                    </Option>
                    <Option value="employmentStatus-Other">
                      {lan.PersonalIdentification.employment_status_5}
                    </Option>
                  </Select>
                </FormItem>
              </div>

              <div className="col-12 col-mb-12 p-0 pt-2">
                {verifyPersonalIdentification ? (
                  <div
                    className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-c3 tc-c2 fs-1em lh-m-1em mb-1"
                    onClick={() => updateData()}>
                    {lan.PersonalIdentification.continue}
                  </div>
                ) : (
                  <div
                    className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-ci tc-c7 fs-1em lh-m-1em mb-1"
                    disabled>
                    {lan.PersonalIdentification.continue}
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

const PersonalIdentification2 = Form.create()(PersonalIdentification);
export default PersonalIdentification2;
// 1243
