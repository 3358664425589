import React, { useEffect, useState } from 'react';
import { termsAndConditionsPDFen, termsAndConditionsPDFes } from './Cookies.module';

const CookieLogic = (props) => {
  /*   const { isEnglish } = props.languaje;
  const [values, setValues] = useState({
    pdf: isEnglish() ? termsAndConditionsPDFen : termsAndConditionsPDFes
  }); */
  const getCookie = localStorage.getItem('cookies');
  const [cookie, setCookie] = React.useState(getCookie);

  /* useEffect(() => {
    setValues({
      ...values,
      pdf: isEnglish() ? termsAndConditionsPDFen : termsAndConditionsPDFes
    });
  }, [isEnglish()]);
 */
  const acceptCookie = () => {
    localStorage.setItem('cookies', true);
    setCookie(true);
  };

  return { acceptCookie, cookie };
};

export default CookieLogic;
