class User {
  constructor({
    name,
    lastname,
    email,
    password,
    type,
    id,
    _id,
    country,
    bank_account,
    account_stripe_connect,
    verified,
    extras
  }) {
    this.name = name;
    this.lastname = lastname;
    this.email = email;
    this.password = password;
    this.type = type;
    this.id = id || _id;
    this.country = country;
    this.bank_account = bank_account;
    this.account_stripe_connect = account_stripe_connect;
    this.verified = verified;
    this.extras = extras;
  }

  isChilean() {
    return this.country === 'CL';
  }

  hasBankData() {
    return Object.keys(this.bank_account).length !== 0;
  }

  hasStripeAccount() {
    return this.account_stripe_connect && this.account_stripe_connect.length > 0;
  }

  get hasExtras() {
    console.log({ extras: this.extras });
    return this.extras && Object.keys(this.extras).length !== 0;
  }
}

export default User;
