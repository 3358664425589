import Formatter from '../Formatter/Formatter';

class Wallet {
  static get DEFAULT_BLOCKCHAIN_BALANCE() {
    return { balance: 0 };
  }

  constructor({ id, balances, data, availableUSD, availableCoins, totalCoins }) {
    const { co2 } = data;
    const [
      egreenCoinBalance = Wallet.DEFAULT_BLOCKCHAIN_BALANCE,
      usdBalance = Wallet.DEFAULT_BLOCKCHAIN_BALANCE
    ] = balances;
    this.egreenCoin = Formatter.formatNumber(egreenCoinBalance.balance, 2);
    this.usd = Formatter.formatNumber(usdBalance.balance, 2);
    this.id = id;
    this.balances = balances;
    this.data = data;
    this.co2 = co2;
    this.availableUSD = Formatter.formatNumber(availableUSD, 2);
    this.availableCoins = Formatter.formatNumber(availableCoins, 2);
    this.totalCoins = Formatter.formatNumber(totalCoins, 2);
  }
}

export default Wallet;
