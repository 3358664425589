import React from 'react';
import '../../../styles/index.css';
import CustomChart from '../Graphic/Graphic';
import { InitPageLogic } from './InitPage.module';

export default function InitPage() {
  const { getExchange, lan } = InitPageLogic();

  return (
    <>
      <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
        <div className="container">
          <div className="col-group">
            <div className="col-4 col-offset-4 p-2 pl-4 pr-4 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-6 bg-cw">
              <div className="col-12 t-c fs-2em fs-m-1_6em fw-500 tc-c2">eGrnXchange</div>
              <CustomChart lan={lan} />

              <p className="montse fs-1em color2 p-1 p-m-2 pt-2 pt-m-0_5 pb-0_5 pb-m-0_3 mb-1">
                {lan.Exchange.trade_egrn}
              </p>

              <div
                className="pointer p-1 pr-4 pl-4 border-radius-24 bg12 box-shadow t-c color10 fs-1em lh-0_8em"
                onClick={() => getExchange()}>
                {lan.Home.access_exchange}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
