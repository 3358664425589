import { useState, useEffect, useContext } from 'react';
import PersonalIdentificationService from '../PersonalIdentification/PersonalIdentification.service';
import GeneralContext from '../../../context/GeneralContext';

const EditPaymentLogic = () => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  const [verifyPersonalIdentification, setVerifyPersonalIdentification] = useState(false);
  const [activeRegister] = useState(false);
  const [creditCardConfirmed, setCreditCardConfirmed] = useState(0);
  const [bankAccountRegistered, setBankAccountRegistered] = useState(0);
  const [section] = useState(1);
  const [values, setValues] = useState({});

  useEffect(() => {
    async function fetchData() {
      const dataUser = await PersonalIdentificationService().getData();
      const { id, email, bank_account = {} } = dataUser;
      const { owner, bankName, accountNumber, accountType, rut } = bank_account;
      setValues({
        ...values,
        id,
        owner,
        email,
        bankName,
        rut,
        accountNumber,
        accountType: accountType ? `accountType-${accountType}` : null
      });
      setVerifyPersonalIdentification(verifiedStatus());
    }
    fetchData();
  }, []);

  const onChange = async (e) => {
    let id, value;
    if (e.target !== undefined) {
      id = e.target.id;
      value = e.target.value;
    } else {
      const split = e.split('-', 2);
      if (split.length <= 1) return;
      id = split[0];
      value = split[1];
    }
    const obj = {
      [`${id}`]: value
    };
    setValues({ ...values, ...obj });
    setVerifyPersonalIdentification(verifiedStatus);
  };

  const verifiedStatus = () =>
    values.owner !== '' &&
    values.email !== '' &&
    values.rut !== '' &&
    values.accountType !== '' &&
    values.bankName !== '' &&
    values.accountNumber !== '';

  const updateData = async () => {
    setBankAccountRegistered(1);
    const { owner, email, bankName, accountNumber, accountType, rut } = values;
    const account = {
      owner,
      email,
      bankName,
      accountNumber,
      rut,
      accountType: accountType.includes('accountType-')
        ? accountType.replace('accountType-', '')
        : accountType
    };
    await PersonalIdentificationService().updateData({ account });
    setBankAccountRegistered(2);
  };

  const testTimeOut1 = () => {
    setCreditCardConfirmed(1);

    setTimeout(() => {
      setCreditCardConfirmed(3);
    }, 1200);
  };

  return {
    onChange,
    updateData,
    testTimeOut1,
    verifyPersonalIdentification,
    activeRegister,
    creditCardConfirmed,
    bankAccountRegistered,
    section,
    values,
    lan,
    setGeneralValues
  };
};
export default EditPaymentLogic;
