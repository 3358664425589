import Session from '../Session/Session';
import CustomError from '../Error/CustomError';

class Response {
  constructor(response = {}, Type) {
    this.response = response;
    this.Type = Type;
  }

  wasSuccessfull() {
    if (Number(this.response.status) >= 200 && Number(this.response.status) < 300) {
      return this;
    } else {
      if (Number(this.response.status === 401)) {
        Session.logout();
        return (window.location.href = '/');
      }
      throw new CustomError(this.response.data);
    }
  }

  hasError() {
    return (
      !this.response.status ||
      (Number(this.response.status) >= 500 && Number(this.response.status) <= 599) ||
      (Number(this.response.status) >= 400 && Number(this.response.status) <= 499)
    );
  }

  getData() {
    return this.response.data || {};
  }

  getSingle() {
    return this.getData();
  }

  getMultiple() {
    const { data } = this.response;
    let { resources, count } = data;
    // resources = resources.map((resource) => new this.Type(resource));
    return {
      resources,
      count
    };
  }
}

export default Response;
