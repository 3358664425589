import { useState, useEffect, useContext } from 'react';
import CodePages from '../../../classes/CodePages/CodePages';
import GeneralContext from '../../../context/GeneralContext';

const OpenOrdersLogic = (OpenOrdersService) => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  const [values, setValues] = useState({ filteredTransactions: [] });
  const [show, setShow] = useState('All');

  useEffect(() => {
    async function fetchData() {
      const offers = await OpenOrdersService().getOffers();
      const allTransaction = offers.resources ? offers.resources : [];

      setValues({
        ...values,
        allTransaction,
        filteredTransactions: allTransaction
      });
    }
    fetchData();
  }, []);

  const theDataSet = () => {
    const countOffers = values.allTransaction.length;
    setGeneralValues((old_values) => ({
      ...old_values,
      section: CodePages.ALL_OFFERS,
      params: { countOffers }
    }));
  };

  useEffect(() => {
    let filteredTransactions = values.allTransaction || [];
    filteredTransactions = filteredTransactions.filter(
      (transaction) => show === 'All' || transaction.type === show
    );
    setValues({ ...values, filteredTransactions });
  }, [show]);

  return {
    values,
    show,
    setShow,
    theDataSet,
    lan
  };
};
export default OpenOrdersLogic;
