import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './TopHeader.css';
import Storage from '../../classes/Storage/Storage';

function TopHeader(props) {
  const history = useHistory();
  const { REACT_APP_PAGE_DOMAIN } = process.env;
  const [active, setActive] = useState('point');
  const [text_lan, setTextLan] = useState('EN');

  async function checkSession() {
    let storage = new Storage('lan');

    if (storage.getItem() === 'es') {
      setTextLan('ES');
    } else {
      setTextLan('EN');
    }

    storage = new Storage('token');
    if (!storage.getItem()) {
      setActive('point');
    } else {
      setActive('point active');
    }
    return true;
  }

  function changeLanguage() {
    props.changeLanguage();
    const storage = new Storage('lan', null);
    if (storage.getItem() === 'es') {
      setTextLan('ES');
    } else {
      setTextLan('EN');
    }
  }

  useEffect(() => {
    checkSession().then(() => {});
  }, []);

  function setCheck() {
    document.getElementById('menu-btn').checked = false;
  }

  function changeRoute() {
    history.push('/');
  }

  return (
    <div className="header sticky">
      <div onClick={changeRoute} className="logo1" />

      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="navicon" />
      </label>

      <div className="menu montse">
        <a
          href={`${REACT_APP_PAGE_DOMAIN}/dashboard`}
          exact="true"
          onClick={() => setCheck((document.getElementById('menu-btn').checked = true))}>
          <span className={active} />
          My Account
        </a>
        <div className="t-c pt-1" exact="true" onClick={changeLanguage}>
          <span className="pointer btn-link-language p-0_2">{text_lan}</span>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
