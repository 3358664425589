import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { OfferCardLogic } from './OffersCard.module';

function OffersCard({ offer, elDataSet = () => { }, canDelete = false, reload = () => { } }) {
  const sellColor = 'c9';
  const buyColor = 'c6';
  const cardColor = 'cw';
  const textColor = 'cb';
  const hover = 'hover-remarked-c5';
  const { values, deleteOffer, lan } = OfferCardLogic(offer, reload);

  return (
    <>
      <input type="radio" name="orders" className="radio-button" />
      <label
        className={`col-3 col-mb-6 col-dt-4 p-0_2 border-radius-6 pointer  ${hover} ${values.type === 'SELL' && `checked-remarked-${sellColor}`
          } ${values.type === 'BUY' && `checked-remarked-${buyColor}`}`}
        onClick={() => elDataSet(offer)}>
        <div className={`bg-${cardColor} border-radius-3 p-0_2 bc-c7-1 box-shadow`}>
          <div className={`open-sans fs-0_6em t-c tc-${textColor}`}>{values.date}</div>

          <div className={` flex flex-space-between p-0_2`}>
            <span className={`open-sans fs-0_8em t-c uppercase fw-600 tc-${textColor}`}>
              {values.type === 'SELL' ? lan.Exchange.type_sell : lan.Exchange.type_buy}
            </span>
            {/*
            <span className={`open-sans fs-0_8em t-c uppercase fw-600 tc-${textColor}`}>
              <b>NEW</b>
            </span>
             new */}
            {values.type === 'SELL' && (
              <FiberManualRecordIcon className={`fc-${sellColor} box-0_6em`} />
            )}
            {values.type === 'BUY' && (
              <FiberManualRecordIcon className={`fc-${buyColor} box-0_6em`} />
            )}
          </div>

          <div className="p-0_2 border-radius-3 bc-c7-1 bg-c8">
            <div className={`fs-0_6em t-c tc-${textColor}`}>EGRN</div>
            <div className={`open-sans t-c fs-1_4em lh-1em tc-${textColor} fw-600`}>
              {values.amount}
            </div>

            <div className={`open-sans t-c fs-0_8em tc-${textColor}`}>
              <span className={`open-sans fs-1em t-c tc-${textColor}`}>USD &nbsp;</span>
              {values.price}
            </div>
          </div>
          <div className={`fs-0_6em tc-${textColor} uppercase mt-0_5 mb-0_2 t-c fw-600`}>
            {values.status}
          </div>
          {canDelete && (
            <button className="delete-button-exchange" id={values.id} onClick={deleteOffer}>
              DELETE
            </button>
          )}
        </div>
      </label>
    </>
  );
}
export default OffersCard;
