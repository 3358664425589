import { useState, useEffect, useContext } from 'react';
import GeneralContext from '../../../context/GeneralContext';

const WalletLogic = (WalletService) => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  const [values, setValues] = useState({
    availableCoins: 0.0,
    availableUSD: 0.0,
    egreenCoin: 0.0,
    usd: 0.0
  });

  useEffect(() => {
    async function fetchData() {
      const response = await WalletService().getData();
      setValues(response);
    }
    fetchData();
  }, []);

  return {
    values,
    lan,
    setGeneralValues
  };
};
export default WalletLogic;
