import { useState, useEffect, useContext } from 'react';
import Offer from '../../../classes/Offer/Offer';
import NewOffersService from '../NewOffers/newOffers.service';
import GeneralContext from '../../../context/GeneralContext';

const OfferCardLogic = (offer, reload) => {
  const { lan } = useContext(GeneralContext);
  const [values, setValues] = useState({});
  const [offerObject] = useState(new Offer(offer));

  async function deleteOffer(e) {
    const { id } = e.target;
    if (window.confirm('Are you sure?')) {
      await NewOffersService().deleteOffer(id);
      reload();
    }
  }

  useEffect(() => {
    async function fetchData() {
      const offerData = new Offer(offer);
      setValues({
        ...values,
        ...offerData
      });
    }
    fetchData();
  }, [offer]);

  return {
    values,
    offerObject,
    deleteOffer,
    lan
  };
};
export default OfferCardLogic;
