import moment from "moment";
//const host = 'https://devapiegreen.azurewebsites.net/api';
const host = process.env.REACT_APP_API;

async function callSaveCo2(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    //Authorization: "Bearer " + token
  };
  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callProjects(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  // let host = 'https://devapiegreen.azurewebsites.net/api';

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callSavePretransaction(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callGetPretransaction(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  // let host = 'https://devapiegreen.azurewebsites.net/api';

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callPay(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    //Authorization: "Bearer " + token
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callCheckPay(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    //Authorization: "Bearer " + token
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callUpdatePretransaction(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    //Authorization: "Bearer " + token
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

/****  User api ****/
async function callRegister(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callDeleteAccount(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callGetUser(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callUpdateUserCognitoId(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callUpdateUserById(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callSignin(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callSendEmail(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  // let host = 'https://devapiegreen.azurewebsites.net/api';
  //let host = 'http://localhost:3000/api';
  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

/* Exchange*/
async function callBuyOffer(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callOffer(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callEndpoint(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url = host + endpoint;
  const response = await fetch(url, options);
  const { status } = response;
  const data = Number(status) === 204 ? {} : await response.json();

  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

const api = {
  flight: {
    saveCo2(parametros) {
      parametros.date = moment(parametros.date).format("DD-MM-YYYY");
      let obj = {};
      obj.activity_id = "dfjr-44fr-flight";
      obj.partner_id = process.env.REACT_APP_ID_PARTNER;
      obj.data = parametros;
      return callSaveCo2("/users/co2", {
        method: "POST",
        body: JSON.stringify(obj),
      });
    },
  },
  projects: {
    getProjects() {
      return callProjects(
        // parametros.token,
        "/projects",
        {
          method: "GET",
        }
      );
    },
  },
  pretransaction: {},
  stripe: {
    pay(params) {
      return callPay("/create-checkout-session", {
        method: "POST",
        body: JSON.stringify(params),
      });
    },
    checkPay(parametros) {
      return callCheckPay(
        //  parametros,
        "/check-pay-session",
        {
          method: "POST",
          body: JSON.stringify(parametros),
        }
      );
    },
  },
  user: {
    register(parametros) {
      return callRegister(
        //  parametros,
        "/users",
        {
          method: "POST",
          body: JSON.stringify(parametros),
        }
      );
    },
    signin(parametros) {
      return callSignin(
        //  parametros,
        "/users/signin",
        {
          method: "POST",
          body: JSON.stringify(parametros),
        }
      );
    },
    getUser(parametros) {
      return callGetUser(
        //  parametros,
        "/decodeToken",
        {
          method: "POST",
          body: JSON.stringify(parametros),
        }
      );
    },
    updateUserCognitoId(parametros) {
      return callUpdateUserCognitoId(
        //  parametros,
        "/users",
        {
          method: "PUT",
          body: JSON.stringify(parametros),
        }
      );
    },
  },
  email: {
    send(parametros) {
      return callSendEmail(
        //  parametros,
        "/email",
        {
          method: "POST",
          body: JSON.stringify(parametros),
        }
      );
    },
  },
  exchange: {
    offers(parametros) {
      return callOffer(
        // parametros,
        "/offers",
        {
          method: "POST",
          body: JSON.stringify(parametros),
        }
      );
    },
    confirmProcessOffer(parametros) {
      return callEndpoint(
        // parametros,
        "/confirm-offers",
        {
          method: "POST",
          body: JSON.stringify(parametros),
        }
      );
    },
    graphicWeek(parametros) {
      return callBuyOffer("/graphics", {
        method: "GET",
        // body: JSON.stringify(parametros)
      });
    },
  },
};
export default api;
