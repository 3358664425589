import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import GeneralContext from '../context/GeneralContext';

import TopHeader from '../components/TopHeader/TopHeader';

import Airlines from '../components/Airline/airlines';
import ExchangePage from '../components/Exchange/ExchangePage';
import GeneralError from '../components/Error/';
import Cookies from '../components/Cookies2/Cookies2';
import Language from '../classes/Language/Language';
import Session from '../components/Session/Session';
import ErrorPage from '../components/Exchange/Error/Error';
import Checkout from './main/Checkout/Checkout';

function Router() {
  const language = new Language();
  const [generalValues, setGeneralValues] = useState({
    cookies: false,
    lan: language.getFullObject(),
    section: 1
  });

  useEffect(() => {
    document.body.style.backgroundImage = 'linear-gradient(to right, #0FA9C9, #5CC882)';

    if (!language.getLanguage()) {
      language.setLanguage();
    }

    setGeneralValues({
      cookies: false,
      lan: language.getFullObject()
    });
  }, []);

  const changeLanguage = () => {
    const language = new Language();
    if (language.isSpanish()) {
      language.changeLanguage('en');
      setGeneralValues({ ...generalValues, lan: language.getFullObject() });
    } else {
      language.changeLanguage('es');
      setGeneralValues({ ...generalValues, lan: language.getFullObject() });
    }
  };

  return (
    <GeneralContext.Provider value={{ ...generalValues, setGeneralValues }}>
      <BrowserRouter>
        <TopHeader changeLanguage={changeLanguage} />
        <Switch>
          <Route path={['/', '/egrnxchange']} exact>
            <ExchangePage lan={generalValues.lan} />
          </Route>

          <Route
            path="/checkout/:session_id"
            exact
            render={({ match }) => {
              return <Checkout session_id={match.params.session_id} />;
            }}
          />

          <Route
            path="/checkoutfailed/:session_id"
            exact
            render={({ match }) => {
              return <Checkout page={9} session_id={match.params.session_id} />;
            }}
          />

          <Route path="/airlines/" exact>
            <Airlines lan={generalValues.lan} />
          </Route>

          <Route path="/session" exact>
            <Session />
          </Route>

          <Route path="/error" exact>
            <GeneralError lan={generalValues.lan} />
          </Route>
          <Route path="/new">
            <ErrorPage lan={generalValues.lan} />
          </Route>
          <Route component={GeneralError} />
        </Switch>
        <Cookies lan={generalValues.lan} />
      </BrowserRouter>
    </GeneralContext.Provider>
  );
}

export default Router;
