import { toHaveFormValues } from '@testing-library/jest-dom';
import React from 'react';
import CookieLogic from './Cookies.logic';
import { cookieLogic } from './Cookies.module';

function Cookies(props) {


  const { acceptCookie, cookie, values } = cookieLogic();
  return (
    <div>
      {cookie ? (
        ''
      ) : (
        <div id="cookies" className={cookie ? 'none' : ' cookiesbox container p-4 p-m-2 vw-100'}>
          <div className="col-group">
            <div className="col-6">
              <h1 className="t-m-c">{props.lan.Cookie.cookie_title}</h1>
              <p className="fs-0_8em t-m-c">
                {props.lan.Cookie.cookie_text}{' '}
                <a className="fw-700 fs-1em" href="/docs/legaleng.pdf" target="_blank">
                  {props.lan.Cookie.cookie_anchor}
                </a>
                .
              </p>
            </div>
            <div className="col-6 flex align-item-center flex-center pt-m-1 t-m-c">
              <span
                onClick={acceptCookie}
                className="btn-accept-cookie bg7 color4 p-1 fs-0_8em fw-500">
                 {props.lan.Cookie.cookie_button}  
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cookies;
