import React from 'react';
import '../../../styles/index.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CodePages from '../../../classes/CodePages/CodePages'
import MatiPendingLogic from './MatiPending.logic';
function MatiPending() {
  // const classes = useStyles();
  const { lan, setGeneralValues } = MatiPendingLogic();
  return (
    <>
      <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
        <div className="container">
          <div className="col-group">
            <div className="col-4 col-offset-4 p-0 pt-0_2 border-radius-tl-6 border-radius-tr-6 bg-c1 flex align-left">
              <div
                className="p-0_5 flex align-item-center flex-start cursor-pointer"
                onClick={() =>
                  setGeneralValues((old_values) => ({
                    ...old_values,
                    section: CodePages.INIT_PAGE
                  }))
                }>
                <ArrowBackIosIcon className="fc-cw" />
                <span className="tc-cw fw-500 fs-0_8em">{lan.Exchange.back}</span>
              </div>
            </div>

            <div className="col-4 col-offset-4 p-2 pt-1 pt-m-0 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-bl-6 border-radius-br-6 bg-c1">
              <p className="fs-2em fs-m-1_6em lh-1_2em p-1 pt-m-0_5 pb-0_5 pb-m-0_3 fw-500 tc-cw t-c mb-1">
                {lan.Exchange.mati_pending}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MatiPending;
