import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Formatter from '../../../classes/Formatter/Formatter';
import '../../../styles/index.css'
import SuccessfulOperationLogic from './SuccessfulOperation.logic';
import Fee from '../../../classes/Fee/Fee';
import ArrowBack from '../ArrowBack/ArrowBack'

function SuccessfulOperation({ params = { offer: {} }}) {
  // const classes = useStyles();

  const { offerData, total, fee, message, operation, lan } = SuccessfulOperationLogic(params.offer, lan);
  const mainColor = 'c1';

  return (
    <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
      <div className="container">
        <div className="col-group">
          {/* <div className="col-4 col-offset-4 p-0 pt-0_2 border-radius-tl-6 border-radius-tr-6 bg-cw flex align-left"> */}
          <div className="sticky-64 border-radius-tl-6 border-radius-tr-6 col-8 col-dt-4 col-offset-2 col-dt-offset-4 p-0 pt-0_2 bg-cw flex align-left">
            <ArrowBack/>
          </div>

          <div className="col-8 col-dt-4 col-offset-2 col-dt-offset-4 p-2 pt-1 pt-m-0 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-bl-6 border-radius-br-6 bg-cw">
            <div className="col-group pl-m-1 pr-m-1">
              <p className={`col-12 fs-1_6em fs-m-1_4em fw-500 tc-${mainColor} t-c`}>
                {lan.SuccessfulOperation.successful_operation}
              </p>
              <div className="col-12 pt-2 pb-3 mih-16 t-c">
                <p className="fs-1_2em lh-1em tc-cb-90 p-0">{message}</p>
                <p className="fs-1_2em lh-0_8em tc-cb-90 p-0">
                  {lan.SuccessfulOperation.discount} {Fee.FORMATTED_FEE}
                  {lan.SuccessfulOperation.fee_commission}
                </p>
                <div className="col-12 flex flex-center">
                  <div
                    className={`fit border-radius-6 bc-c5-1 p-0_5 flex flex-column align-item-center bg-cw`}>
                    <div className="pb-0_2 fs-0_6em lh-1em fw-400 t-c tc-cd">{operation}</div>
                    <div className="open-sans pb-0_2 fs-1_6em lh-1em t-c tc-cd">
                      {Formatter.formatNumber(offerData.amount, 2)} EGRN
                    </div>
                  </div>
                </div>

                <div className="col-12 flex flex-center">
                  <div
                    className={`fit border-radius-6 bc-c5-1 p-0_5 flex flex-column align-item-center bg-cw`}>
                    <div className="pb-0_2 fs-0_6em lh-1em fw-400 t-c tc-cd">
                      {lan.SuccessfulOperation.total}
                    </div>
                    <div className="open-sans pb-0_2 fs-1_6em lh-1em t-c tc-cd">
                      ${Formatter.formatNumber(total, 2)} USD
                    </div>
                  </div>
                </div>
                <div className="col-12 flex flex-center">
                  <div
                    className={`fit border-radius-6 bc-c5-1 p-0_5 flex flex-column align-item-center bg-cw`}>
                    <div className="pb-0_2 fs-0_6em lh-1em fw-400 t-c tc-cd">FEE</div>
                    <div className="open-sans pb-0_2 fs-1_6em lh-1em t-c tc-cd">
                      ${Formatter.formatNumber(fee, 2)} USD
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessfulOperation;
