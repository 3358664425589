import API from '../../../classes/API/API';
import Response from '../../../classes/Response/Response';
import Transfer from '../../../classes/Transfer/Transfer';

const WithdrawUSDService = () => {
  const createTransfer = async (transfer) => {
    const api = new API(`${process.env.REACT_APP_API}/transfers`);
    const response = new Response(await api.post(transfer)).wasSuccessfull().getSingle();
    return new Transfer(response);
  };

  const confirmTransfer = async (transfer) => {
    const { id, code } = transfer;
    const api = new API(`${process.env.REACT_APP_API}/transfers/${id}/validators`);
    const response = new Response(await api.post({ code })).wasSuccessfull().getSingle();
    return new Transfer(response);
  };

  return {
    createTransfer,
    confirmTransfer
  };
};

export default WithdrawUSDService;
