class CustomError {
  constructor(data) {
    this.data = data;
  }

  getMessage() {
    switch (true) {
      case this.data.errors && this.data.errors.length > 0:
        return this.data.errors;
      case this.data.message && this.data.message.length > 0:
        return this.data.message;
      default:
        return "General Error";
    }
  }
}

export default CustomError;
