import React from 'react';
import ArrowBack from '../ArrowBack/ArrowBack'
import CodePages from '../../../classes/CodePages/CodePages';
import { SelectAccountLogic, useStyles } from './SelectAccount.module';

function SelecAccount({ }) {
  const { setGeneralValues, lan } = SelectAccountLogic();
  const classes = useStyles();

  return (
    <>
      <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
        <div className="container">
          <div className="col-group">
            <div className="col-4 col-offset-4 p-0 pt-0_2 border-radius-tl-6 border-radius-tr-6 bg-cw flex align-left">
            <ArrowBack section={CodePages.INIT_PAGE}/> 
            </div>

            <div className="col-4 col-offset-4 p-2 pt-1 pl-4 pr-4 pt-m-0 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-bl-6 border-radius-br-6 bg-cw">
              <div className="col-12 t-c fs-1_8em lh-1em fw-500 tc-c6 mb-1">
                {lan.SelectAccount.select}
              </div>

              <div className="col-12 mb-1">
                <div
                  className="pointer box-shadow p-0_2 border-radius-24 t-c bg-c1 tc-cw fs-1_2em lh-m-1em mb-1"
                  onClick={() => setGeneralValues((old_values) => ({
                    ...old_values,
                    section: CodePages.PERSONAL_IDENTIFICATION
                  }))}>
                  {lan.SelectAccount.personalAccount}
                </div>
                <p className="color2 p-m-2 pb-0_5 pt-m-0_5 pb-m-0_3 lh-1em">
                  {lan.SelectAccount.invest}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelecAccount;
