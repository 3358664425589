import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function OffersCardSkeleton() {
  const heightDef1 = 7;
  const heightDef2 = 10;

  return (
    <>
      <label className={`col-3 col-mb-6 col-dt-4 p-0_2 border-radius-6 pointer t-c`}>
        <div className={`border-radius-3 p-0_2 bc-c7-1 box-shadow`}>
          <div className={`t-c`}>{/* <Skeleton width={60} height={heightDef1} /> */}</div>

          <div className={` flex flex-space-between p-0_2`}>
            <span className={`fs-0_8em`}>
              <Skeleton width={30} height={heightDef2} />
            </span>
            <Skeleton width={12} height={12} circle />
          </div>

          <div className="p-0_2 border-radius-3 bc-c7-1">
            <div className={`fs-0_6em`}>
              <Skeleton width={40} height={heightDef1} />
            </div>
            <div className={`fs-1_4em lh-1em`}>
              <Skeleton width={80} height={heightDef1} />
            </div>

            <div className={`fs-0_8em `}>
              <span className={`fs-1em`}>
                <Skeleton width={60} height={heightDef1} />
              </span>
            </div>
          </div>
          <div className={`fs-0_6em  mt-0_5 mb-0_2`}>
            <Skeleton width={40} height={heightDef1} />
          </div>
        </div>
      </label>
    </>
  );
}
export default OffersCardSkeleton;
