import React, { Component } from "react";
import "./BreadcrumbsScss.scss";

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <ul className="breadcrumbs">
        <li className="flex flex-column align-item-center">
          <img
            className=""
            src={
              this.props.iconTree
                ? "/svg/icons/tree2.svg"
                : "/svg/icons/tree1.svg"
            }
            alt={"tree"}
          />
          <span className="fs-0_6em color2 t-c">Select your project.</span>
        </li>

        <li className="flex flex-column align-item-center">
          <img
            className=""
            src={
              this.props.iconCard
                ? "/svg/icons/card2.svg"
                : "/svg/icons/card1.svg"
            }
            alt={"card"}
          />
          <span className="fs-0_6em color2 t-c">Pay your offset.</span>
        </li>

        <li className="flex flex-column align-item-center">
          <img
            className=""
            src={
              this.props.iconFlag
                ? "/svg/icons/flag2.svg"
                : "/svg/icons/flag1.svg"
            }
            alt={"flag"}
          />
          <span className="fs-0_6em color2 t-c">Receiving your reward</span>
        </li>
      </ul>
    );
  }
}
export default Breadcrumbs;
