import React from 'react';
import CodePages from '../../../classes/CodePages/CodePages';
import { WalletLogic, WalletService } from './Wallet.module';

function Wallet({ type }) {
  // const classes = useStyles();
  const { values, lan, setGeneralValues } = WalletLogic(WalletService);

  const confirmBtn = false;
  const sellBtn = true;
  const buyBtn = true;
  const transferBtn = true;
  const withdrawBtn = true;
  const mainColor = 'c2';
  const secondColor = 'c6';

  return (
    <div className="p-0_5 pl-1 pr-1 border-radius-6 bc-c7-1 bg-c8 mb-1">
      {type === 'BUY' && (
        <div className="flex">
          {transferBtn ? (
            <button
              className={`bg-${mainColor} fs-1em lh-1em p-1 flex-grow-1 border-radius-tl-12 tc-cw cursor-pointer`}
              onClick={() =>
                setGeneralValues((old_values) => ({
                  ...old_values,
                  section: CodePages.TRANSFER_USD_PAGE
                }))
              }>
              {lan.Exchange.transfer_to_buy}
            </button>
          ) : (
            <button className="bg-ci fs-1em lh-1em p-1 flex-grow-1 border-radius-tl-12 tc-cw cursor-pointer">
              {lan.Exchange.transfer_to_buy}
            </button>
          )}
          {withdrawBtn ? (
            <button
              className={`bg-${secondColor} fs-1em lh-1em p-1 flex-grow-1 border-radius-tr-12 tc-cw cursor-pointer`}
              onClick={() =>
                setGeneralValues((old_values) => ({
                  ...old_values,
                  section: CodePages.WITHDRAW_PAGE
                }))
              }>
              {lan.Exchange.withdraw_usd}
            </button>
          ) : (
            <button className="bg-ci fs-1em lh-1em p-1 flex-grow-1 border-radius-tr-12 color4 cursor-pointer">
              {lan.Exchange.withdraw_usd}
            </button>
          )}
        </div>
      )}

      <div className="bg4 border-radius-12 bc-c7-1 p-0_5">
        {type === 'SELL' && (
          <>
            <div className="fs-1_2em fw-600">{lan.Exchange.current_coins}</div>
            <div className={`fs-2_8em  fw-700 tc-${mainColor}`}>{values.egreenCoin}</div>
            <div className={`fs-1em  fw-400 tc-${mainColor}`}>
              {lan.Exchange.available}
              <span className="fw-600"> {values.availableCoins} </span>
              EGRN
            </div>
          </>
        )}
        {type === 'BUY' && (
          <div className="bg4 p-1 border-radius-bl-12 border-radius-br-12 bc-c7-1">
            <div className="fs-1em fw-400">{lan.Exchange.current_usd}</div>
            <div className={`fs-2_8em  fw-700 tc-${mainColor}`}>{values.usd}</div>
            <div className={`fs-1em  fw-400 tc-${mainColor}`}>
              {lan.Exchange.available}
              <span className="fw-600"> {values.availableUSD} </span>
              USD
            </div>
          </div>
        )}
      </div>
      {type === 'SELL' &&
        (sellBtn ? (
          <div
            className={`pointer p-0_5 mt-0_5 mb-0_5 pr-2 pl-2 border-radius-24 bg-c3 box-shadow tc-${mainColor} fs-1_2em`}
            onClick={() =>
              setGeneralValues((old_values) => ({
                ...old_values,
                section: CodePages.CREATE_SELL_OFFER_PAGE
              }))
            }>
            {lan.Exchange.sell}
          </div>
        ) : (
          <div className="pointer p-0_5 mt-0_5 mb-0_5 pr-2 pl-2 border-radius-24 bg-ci box-shadow tc-c7 fs-1_2em">
            {lan.Exchange.sell}
          </div>
        ))}

      {type === 'BUY' &&
        (confirmBtn ? (
          <>
            <div
              className={`pointer p-0_5 mt-0_5 mb-0_5 pr-2 pl-2 border-radius-24 bg-c9 box-shadow tc-${mainColor} fs-1_2em`}
              onClick={() =>
                setGeneralValues((old_values) => ({
                  ...old_values,
                  section: CodePages.DETAIL_PROJECT_OFFSET_FOR_REWARDS
                }))
              }>
              Confirm
            </div>
            <p className="fs-1em lh-1_2em t-c">Confirm transfered amount.</p>
          </>
        ) : buyBtn ? (
          <div
            className={`pointer p-0_5 mt-0_5 mb-0_5 pr-2 pl-2 border-radius-24 bg-c3 box-shadow tc-${mainColor} fs-1_2em`}
            onClick={() =>
              setGeneralValues((old_values) => ({
                ...old_values,
                section: CodePages.CREATE_BUY_OFFER_PAGE
              }))
            }>
            {lan.Exchange.buy}
          </div>
        ) : (
          <div className="pointer p-0_5 mt-0_5 mb-0_5 pr-2 pl-2 border-radius-24 bg-ci box-shadow tc-c7 fs-1_2em">
            {lan.Exchange.buy}
          </div>
        ))}
    </div>
  );
}

export default Wallet;
