import { useContext, useEffect, useState } from 'react';
import GeneralContext from '../../../context/GeneralContext';

const NewOffersLogic = (props) => {
  const [title, setTitle] = useState('');
  const { lan, setGeneralValues } = useContext(GeneralContext);

  useEffect(() => {
    setTitle(props.type === 'BUY' ? lan.Exchange.buy : lan.Exchange.sell);
  }, []);

  useEffect(() => {
    setTitle(props.type === 'BUY' ? lan.Exchange.buy : lan.Exchange.sell);
  }, [props]);

  return {
    title,
    lan,
    setGeneralValues
  };
};

export default NewOffersLogic;
