import { useContext } from 'react';
import ExchangeService from '../Exchange/Exchange.service';
import GeneralContext from '../../../context/GeneralContext';
import ChooserPage from '../../../classes/ChooserPage';

const InitPageLogic = () => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  const getExchange = async () => {
    //decido si voy a completar registro o al exchange directamente
    try {
      const user = await ExchangeService().getData();
      const chooser = new ChooserPage(user);
      console.log({ section: chooser.goToPage() });
      return setGeneralValues((old_values) => ({ ...old_values, section: chooser.goToPage() }));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getExchange,
    lan
  };
};
export default InitPageLogic;
