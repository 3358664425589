import { useEffect, useState, useContext } from 'react';
import moment from 'moment/moment';
import Flags from '../../../classes/Flags/Flags';
import CodePages from '../../../classes/CodePages/CodePages';
import PersonalIdentificationService from './PersonalIdentification.service';
import GeneralContext from '../../../context/GeneralContext';

const PersonalIdentificationLogic = () => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  const [verifyPersonalIdentification, setVerifyPersonalIdentification] = useState(false);
  const [values, setValues] = useState({});
  const [flags, setFlags] = useState([]);

  const updateData = async () => {
    const extras = {
      type: 'personal',
      personal_day: values.day,
      personal_month: values.month,
      personal_year: values.year,
      postalCode: values.postalCode,
      unit: values.unit,
      locality: values.locality,
      country: values.country,
      whyEgreen: values.whyEgreen,
      sourceIncome: values.sourceIncome,
      employmentStatus: values.employmentStatus
    };
    // await UserService.updateData({ extras, verified: 'data_loaded' });
    await PersonalIdentificationService().updateData({ extras, verified: 'data_loaded' });
    setGeneralValues((old_values) => ({
      ...old_values,
      section: CodePages.MATI_PAGE
    }));
  };

  const processDate = async (moment, date) => {
    const split = date.split('/', 3);
    if (split.length === 3) {
      const day = split[0];
      const month = split[1];
      const year = split[2];
      const obj = { day, month, year };
      await setValues({ ...values, ...obj });
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().subtract(18, 'years');
  };

  const onChange = async (e) => {
    let id;
    let value;
    if (e.target !== undefined) {
      id = e.target.id;
      value = e.target.value;
    } else {
      const split = e.split('-', 2);
      if (split.length <= 1) {
        return;
      }
      id = split[0];
      value = split[1];
    }
    const obj = {
      [`${id}`]: value
    };

    await setValues({ ...values, ...obj });
  };

  const verifiedStatus = () =>
    values.day &&
    values.month &&
    values.year &&
    values.unit &&
    values.locality &&
    values.postalCode &&
    values.country &&
    values.whyEgreen &&
    values.sourceIncome &&
    values.employmentStatus;

  useEffect(() => {
    async function fetchData() {
      const dataUser = await PersonalIdentificationService().getData();
      const { id, verified, name, lastname } = dataUser;
      setValues({ ...values, id, verified, name, lastname });
      if (verified !== 'starter') {
        setGeneralValues((old_values) => ({
          ...old_values,
          section: CodePages.MATI_PAGE
        }));
      }
      const response = Flags.flagData();
      setFlags(response);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setVerifyPersonalIdentification(verifiedStatus());
  }, [values]);

  return {
    updateData,
    onChange,
    processDate,
    disabledDate,
    setGeneralValues,
    values,
    flags,
    verifyPersonalIdentification,
    lan
  };
};
export default PersonalIdentificationLogic;
