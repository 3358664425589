import React from 'react';
import { Select } from 'antd';
import FlagJson from './FlagsData.json';
const { Option } = Select;

class Flags {
  static flagData() {
    let flags = [];

    for (let key in FlagJson) {
      if (FlagJson.hasOwnProperty(key)) {
        let value = FlagJson[key];
        flags.push(
          <Option value={`country-${value.code}`}>
            <img src={`/svg/flags/${value.code}.svg`} alt="flag" width={20} />
            &nbsp;
            {value.name}
          </Option>
        );
      }
    }
    return flags;
  }
}

export default Flags;
