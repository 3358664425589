import React, { useState } from 'react';
import './PersonalDetails.styles.css';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { ExchangeLogic } from '../Exchange/Exchange.module';

import StripeConnect from '../StripeConnect/StripeConnect';
import CodePages from '../../../classes/CodePages/CodePages';

function PersonalDetails() {
  const [activeclass3, setActiveclass3] = useState(false);
  const { values, setGeneralValues, lan } = ExchangeLogic();
  const mainColor = 'c2';
  return (
    <section className={`col-12 bg-${mainColor} p-0_5 border-radius-12 mb-1 box-shadow`}>
      <div
        className="col-12 flex flex-space-between align-item-center pl-1 cursor-pointer"
        onClick={() => setActiveclass3(!activeclass3)}>
        <span className="color4 fs-1_2em fs-m-1em lh-1_2em t-m-l">
          {lan.Exchange.personal_account_details}
        </span>
        <ArrowRightIcon
          className={`fc-cw box-1_4em transition ${!activeclass3 ? '' : 'rotate-down'}`}
        />
      </div>
      <section className={`col-12 border-radius-6 p-1 ${!activeclass3 ? 'none' : ''}`}>
        <div className="col-12 p-0_2 t-l fs-1em fs-m-0_8em lh-1_2em ">
          <span className="pr-0_5 tc-cw">
            <b color="white">{lan.Exchange.name}:</b>{' '}
            {values.user.bank_account ? values.user.bank_account.owner : '2223'}
          </span>
        </div>
        <div className="col-12 p-0_2 t-l fs-1em fs-m-0_8em lh-1_2em ">
          <span className="pr-0_5 tc-cw">
            <b color="white">{lan.Exchange.email}:</b>{' '}
            {values.user.bank_account ? values.user.bank_account.email : 'lss'}
          </span>
        </div>
        {values.isChilean && (
          <button
            className="col-12 pointer box-shadow p-0_5 pl-1 pr-1 border-radius-24 t-c fs-1_2em lh-m-1em bg-c3 tc-c2-90"
            onClick={() => setGeneralValues((old_values) => ({ ...old_values, section: CodePages.SETUP_ACCOUNT_BANK_DATA }))}>
            {values.has_bank_data ? lan.Exchange.edit_bank_account : lan.Exchange.bank_account}
          </button>
        )}
        {!values.isChilean && <StripeConnect disabled={false} />}
        {values.has_bank_data && values.isChilean && (
          <div className="col-12 p-0_2 t-l fs-1em fs-m-0_8em lh-1_2em">
            <span className="data-span">
              <b>{lan.Exchange.bank_name}:</b>
              {values.user.bank_account.bankName}
            </span>
            <br />
            <span className="data-span">
              <b>{lan.Exchange.rut}:</b> {values.user.bank_account.rut}
            </span>
            <br />
            <span className="data-span">
              <b>{lan.Exchange.account_number}:</b> {values.user.bank_account.accountNumber}
            </span>
            <br />
            <span className="data-span">
              <b>{lan.Exchange.account_type}: </b>
              {values.user.bank_account.accountType}
            </span>
            <br />
          </div>
        )}
      </section>
    </section>
  );
}

export default PersonalDetails;
