import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import ArrowBack from '../ArrowBack/ArrowBack'
import { EditPaymentLogic } from './EditPayment.module';
import CodePages from '../../../classes/CodePages/CodePages';
import '../../../styles/Exchange.css';
const { Option } = Select;
const FormItem = Form.Item;

function EditPayment(props) {
  const { form } = props;
  const { getFieldDecorator } = form;
  const {
    onChange,
    updateData,
    testTimeOut1,
    setGeneralValues,
    verifyPersonalIdentification,
    activeRegister,
    creditCardConfirmed,
    bankAccountRegistered,
    section,
    values,
    lan,
  } = EditPaymentLogic();

  useEffect(() => {
    const { owner, email, bankName, accountNumber, rut } = values;
    form.setFieldsValue({
      owner,
      email,
      bankName,
      accountNumber,
      rut
    });
  }, [values]);

  return (
    <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
      <div className="container">
        <div className="col-group">
          <div className="col-4 col-offset-4 p-0 pt-0_2 border-radius-tl-6 border-radius-tr-6 bg-cw flex align-left">
          <ArrowBack /> 
          </div>

          <div className="col-4 col-offset-4 p-2 pt-0 pt-m-0 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-bl-6 border-radius-br-6 bg-cw t-c">
            <div className="col-group pl-2 pr-2">
              <div className="fs-1_8em fs-m-1_6em lh-1em tc-c2 fw-500 pt-1 pb-0_2 pb-m-0_2">
                {lan.Exchange.bank_account_title}
              </div>
              <p className="pb-0_2 pt-0 pb-m-0_3 fs-1em lh-1em">{lan.Exchange.bank_account_desc}</p>

              <div className="col-12 p-0 flex">
                {/*<button
          className={`p-0_5 pt-1 pb-1 flex-grow-1 border-radius-tl-12 cursor-pointer fs-0_8em lh-1em tc-c9 ${
            activeRegister ? "bg-c2 fw-600" : "bg-c7 tc-ci"
          }`}
          onClick={() => setState({
            activeRegister: true,
            section: 4
          })}
        >
          Credit Card Registration
        </button> */}
                <button
                  className={`p-0_5 pt-1 pb-1 flex-grow-1 border-radius-tr-12 cursor-pointer fs-0_8em lh-1em tc-c2 ${activeRegister || section !== 1 ? 'bg-c7 tc-ci' : 'bg-c9 fw-600'
                    }`}
                  onClick={() => {
                    setActiveRegister(false);
                    setSection(1);
                  }}>
                  {lan.Exchange.bank_account_section}
                </button>
              </div>
              <div
                className={`col-12 p-2 p-m-1 border-radius-bl-12 border-radius-br-12 ${activeRegister ? 'bg-c2' : 'bg-c9'
                  }`}>
                {activeRegister && (
                  <>
                    {creditCardConfirmed === 0 && (
                      <Form name="creditCardRegister">
                        <FormItem name="firstname" className="">
                          <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c9 uppercase t-l">
                            First Name
                          </div>
                          {getFieldDecorator('firstname', {
                            rules: [
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]
                          })(
                            <Input
                              className="col-12 fs-1em lh-2em bg-c2 border-radius-24 bc-c9-2 tc-c9"
                              onChange={onChange}
                            />
                          )}
                        </FormItem>
                        <FormItem name="lastname" className="">
                          <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c9 uppercase t-l">
                            Last Name
                          </div>
                          {getFieldDecorator('lastname', {
                            rules: [
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]
                          })(
                            <Input
                              className="col-12 fs-1em lh-2em bg-c2 border-radius-24 bc-c9-2 tc-c9"
                              onChange={onChange}
                            />
                          )}
                        </FormItem>
                        <FormItem name="cardnumber" className="">
                          <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c9 uppercase t-l">
                            Card Number
                          </div>
                          {getFieldDecorator('cardnumber', {
                            rules: [
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]
                          })(
                            <Input
                              className="col-12 fs-1em lh-2em bg-c2 border-radius-24 bc-c9-2 tc-c9"
                              onChange={onChange}
                            />
                          )}
                        </FormItem>
                        <div className="col-group p-0">
                          <FormItem name="tru" className="col-6 col-mb-6 pr-0_5">
                            <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c9 uppercase t-l">
                              Thru
                            </div>
                            {getFieldDecorator('tru', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Required'
                                }
                              ]
                            })(
                              <Input
                                placeholder="MM/YY"
                                className="fs-1em lh-2em bg-c2 border-radius-24 bc-c9-2 tc-c9"
                                onChange={onChange}
                              />
                            )}
                          </FormItem>
                          <FormItem name="cvd" className="col-6 col-mb-6 pl-0_5">
                            <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c9 uppercase t-l">
                              CVD
                            </div>
                            {getFieldDecorator('cvd', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Required'
                                }
                              ]
                            })(
                              <Input
                                className="fs-1em lh-2em bg-c2 border-radius-24 bc-c9-2 tc-c9"
                                onChange={onChange}
                              />
                            )}
                          </FormItem>
                        </div>

                        {verifyPersonalIdentification ? (
                          // <div className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-cw tc-c2 fs-1em lh-m-1em mt-2 mb-1" onClick={() => setState({ creditCardConfirmed: 2 })} >
                          <div
                            className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-c9 tc-c2 fs-1em lh-m-1em mt-2 mb-1"
                            onClick={() => testTimeOut1()}>
                            Confirm Details
                          </div>
                        ) : (
                          <div
                            className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-ci tc-c7 fs-1em lh-m-1em mt-2 mb-1"
                            disabled>
                            Confirm Details
                          </div>
                        )}
                      </Form>
                    )}
                    {creditCardConfirmed === 1 && (
                      <div className="col-12 pt-2 pb-3 mih-12">
                        <div id="loader" className="loader ">
                          <div class="loader-inner ball-scale-ripple-multiple">
                            <div className="bc-c9-2"></div>
                            <div className="bc-c9-2"></div>
                            <div className="bc-c9-2"></div>
                          </div>
                        </div>
                        <div className="col-12 tc-c9 fs-1em fs-700 p-0_2">
                          Processing your request
                        </div>
                      </div>
                    )}
                    {creditCardConfirmed === 2 && (
                      <div className="col-12 fs-1_8em fs-m-1_6em lh-1em tc-c9 fw-500 pt-2 pb-3 mih-12">
                        Credit Card Successfully Registered
                      </div>
                    )}
                  </>
                )}
                {!activeRegister && (
                  <>
                    {bankAccountRegistered === 0 && (
                      <Form name="bankAccountRegister">
                        <FormItem name="owner" className="">
                          <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c2 uppercase t-l">
                            Owner
                          </div>
                          {getFieldDecorator('owner', {
                            rules: [
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]
                          })(
                            <Input
                              className="col-12 fs-1em lh-2em bg-c9 border-radius-24 bc-c2-2 tc-c2"
                              onChange={onChange}
                            />
                          )}
                        </FormItem>
                        <FormItem name="email" className="">
                          <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c2 uppercase t-l">
                            Email
                          </div>
                          {getFieldDecorator('email', {
                            rules: [
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]
                          })(
                            <Input
                              type="email"
                              className="col-12 fs-1em lh-2em bg-c9 border-radius-24 bc-c2-2 tc-c2"
                              onChange={onChange}
                            />
                          )}
                        </FormItem>
                        <FormItem name="bankName" className="">
                          <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c2 uppercase t-l">
                            {lan.Exchange.bank_name}
                          </div>
                          {getFieldDecorator('bankName', {
                            rules: [
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]
                          })(
                            <Input
                              className="col-12 fs-1em lh-2em bg-c9 border-radius-24 bc-c2-2 tc-c2"
                              onChange={onChange}
                            />
                          )}
                        </FormItem>
                        <FormItem name="rut" className="">
                          <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c2 uppercase t-l">
                            {lan.Exchange.rut}
                          </div>
                          {getFieldDecorator('rut', {
                            rules: [
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]
                          })(
                            <Input
                              className="col-12 fs-1em lh-2em bg-c9 border-radius-24 bc-c2-2 tc-c2"
                              onChange={onChange}
                            />
                          )}
                        </FormItem>
                        <FormItem name="accountNumber" className="">
                          <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c2 uppercase t-l">
                            {lan.Exchange.account_number}
                          </div>
                          {getFieldDecorator('accountNumber', {
                            rules: [
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]
                          })(
                            <Input
                              type="number"
                              className="col-12 fs-1em lh-2em bg-c9 border-radius-24 bc-c2-2 tc-c2"
                              onChange={onChange}
                            />
                          )}
                        </FormItem>

                        <FormItem className="">
                          <div className="fs-0_6em lh-1em fw-600 p-0_2 pl-2 tc-c2 uppercase t-l">
                            {lan.Exchange.account_type}
                          </div>
                          {getFieldDecorator('accountType', {
                            rules: [
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]
                          })(
                            <div className="col-12 fs-1em lh-2em bg-c9 border-radius-24 bc-c2-2 tc-c2">
                              <Select
                                value={values.accountType}
                                className="col-12"
                                onChange={onChange}>
                                <Option value="accountType-Current Account">
                                  {lan.Exchange.current_account_type}
                                </Option>
                                <Option value="accountType-Vista Account">
                                  {lan.Exchange.vista_account_type}
                                </Option>
                                <Option value="accountType-RUT Account">
                                  {lan.Exchange.rut_account_type}
                                </Option>
                              </Select>
                            </div>
                          )}
                        </FormItem>

                        {verifyPersonalIdentification ? (
                          // <div className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-c2 tc-c9 fs-1em lh-m-1em mt-2 mb-1" onClick={() => setState({ bankAccountRegistered: 2 })} >
                          <div
                            className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-c2 tc-c9 fs-1em lh-m-1em mt-2 mb-1"
                            onClick={() => updateData()}>
                            {lan.Exchange.confirm_details}
                          </div>
                        ) : (
                          <div
                            className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-ci tc-c7 fs-1em lh-m-1em mt-2 mb-1"
                            disabled>
                            {lan.Exchange.confirm_details}
                          </div>
                        )}
                      </Form>
                    )}
                    {bankAccountRegistered === 1 && (
                      <div className="col-12 pt-2 pb-3 mih-12">
                        <div id="loader" className="loader ">
                          <div className="loader-inner ball-scale-ripple-multiple">
                            <div className="bc-c2-2" />
                            <div className="bc-c2-2" />
                            <div className="bc-c2-2" />
                          </div>
                        </div>
                        <div className="col-12 tc-c2 fs-1em fs-700 p-0_2">
                          Processing your request
                        </div>
                      </div>
                    )}
                    {bankAccountRegistered === 2 && (
                      <div className="col-12 fs-1_8em fs-m-1_6em lh-1em tc-c2 fw-500 pt-2 pb-3 mih-12">
                        Bank Account Successfully Registered
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="col-12 p-0 pt-1">
                {bankAccountRegistered === 2 && (
                  <div
                    className="pointer box-shadow p-0_5 p-m-1 border-radius-24 t-c bg-c3 tc-c2 fs-1em lh-m-1em mb-1"
                    onClick={() =>
                      setGeneralValues((old_values) => ({
                        ...old_values,
                        section: CodePages.EXCHANGE_PAGE
                      }))
                    }>
                    {lan.Exchange.back}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const EditPayment2 = Form.create()(EditPayment);
export default EditPayment2;
