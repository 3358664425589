import ES from '../../languages/es.json';
import EN from '../../languages/en.json';

import Storage from '../Storage/Storage';
import CustomError from '../Error/CustomError';

class Language {
  static properties() {
    return [
      'Account',
      'buttons',
      'Checkout',
      'Exchange',
      'Home',
      'Offset4rewards',
      'projects',
      'Payment'
    ];
  }

  constructor(language = 'en') {
    this.language = language === 'en' ? EN : ES;
    this.language_str = language;
    this.getLanguage();
  }

  changeLanguage(new_language) {
    this.language_str = new_language;
    this.language = new_language === 'en' ? EN : ES;
    this.setLanguage();
  }

  isValidMainProperty(property) {
    return Language.properties().include(property);
  }

  getMainProperty(property) {
    if (!this.isValidMainProperty(property)) {
      throw new CustomError('Invalid Property');
    }
    return this.language[property];
  }

  getNestedProperty(main_property, property) {
    return this.getMainProperty(main_property)[property];
  }

  getLanguage() {
    const storage = new Storage('lan', null);
    this.language_str = storage.getItem();
    this.language = this.isSpanish() ? ES : EN;
    return storage.getItem();
  }

  setLanguage() {
    const storage = new Storage('lan', this.language_str);
    return storage.setItem();
  }

  isSpanish() {
    return this.language_str === 'es';
  }

  isEnglish() {
    return this.language_str === 'en';
  }

  // TODO: Borrar en versiones posetriores
  getFullObject() {
    return this.language;
  }
}

export default Language;
