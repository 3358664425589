import { useState, useEffect, useContext } from 'react';
import CodePages from '../../../classes/CodePages/CodePages';
import Storage from '../../../classes/Storage/Storage';
import WalletService from '../Wallet/Wallet.service';
import OpenOrdersService from '../OpenOrders/OpenOrders.service';
import NewOffersService from '../NewOffers/newOffers.service';
import Offer from '../../../classes/Offer/Offer';
import GeneralContext from '../../../context/GeneralContext';

const OpenMarketLogic = () => {
  const { lan, params, setGeneralValues } = useContext(GeneralContext);
  const { countOffers = 4 } = params;
  const [values, setValues] = useState({ filteredTransactions: [] });
  const [show, setShow] = useState('All');
  const [offersSkeleton, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      let offerArray = [];
      for (let i = 0; i < countOffers; i++) {
        offerArray.push(i);
      }
      setOffers(offerArray);
      const response = await WalletService().getData();
      const offers = await OpenOrdersService().getOffers();
      const allTransaction = offers.resources ? offers.resources : [];

      setValues({
        ...values,
        ...response,
        allTransaction,
        filteredTransactions: allTransaction
      });
    }

    fetchData().finally(() => {
      setLoading(false);
    });
  }, []);

  const theDataSet = async (offer) => {
    let valuesData = values;
    const totalPrice = Number(offer.amount * offer.price);
    const availableUSD = Number(values.availableUSD);

    valuesData.sell = offer.type === 'SELL';
    valuesData.buy = offer.type === 'BUY';
    offer.hasFunds = availableUSD >= totalPrice;
    //deshabilitar botón si los USD son mayores a los habilitados
    valuesData.buyable = availableUSD <= totalPrice ? 1 : 2;

    valuesData.offerData = offer;
    valuesData.offerStatus = true;
    valuesData.clickTimestampVerification = 'bg-c8';
    setTimeout(() => {
      valuesData.clickTimestampVerification = 'bg-fade-c3-c8-10';
      setValues({ ...values, valuesData });
    }, 50);
    setValues({ ...values, valuesData });
  };

  useEffect(() => {
    let filteredTransactions = values.allTransaction || [];
    filteredTransactions = filteredTransactions.filter(
      (transaction) => show === 'All' || transaction.type === show
    );
    setValues({ ...values, filteredTransactions });
  }, [show]);

  const processOffer = async () => {
    const offer = new Offer(values.offerData);
    const offerResponse = await NewOffersService().createOffer(offer, true);
    offerResponse.user = new Storage('id').getItem();
    await NewOffersService().processOffer(offerResponse);
    setGeneralValues((old_values) => ({
      ...old_values,
      section: CodePages.CODE_SENDER_PAGE,
      params: offerResponse,
      callback: confirmProcessOrder
    }));
  };

  const confirmProcessOrder = async ({ offer, code }) => {
    await NewOffersService().confirmProcessOffer(offer, code);
    setGeneralValues((old_values) => ({
      ...old_values,
      section: CodePages.SUCCESSFUL_OPERATION,
      params: { offer: new Offer(offer) }
    }));
  };

  return {
    values,
    show,
    loading,
    offersSkeleton,
    lan,
    setGeneralValues,
    setShow,
    theDataSet,
    processOffer
  };
};
export default OpenMarketLogic;
