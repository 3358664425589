import { useState, useEffect, useContext } from 'react';
import GeneralContext from '../../../context/GeneralContext';

const MyCurrentOrdersLogic = (MyCurrentOrdersService) => {
  const { lan } = useContext(GeneralContext);
  const [values, setValues] = useState({ filteredTransactions: [] });
  const [show, setShow] = useState('All');

  async function fetchData() {
    const offers = await MyCurrentOrdersService().getOffers();
    const allTransaction = offers.resources ? offers.resources : [];

    setValues({
      ...values,
      allTransaction,
      filteredTransactions: allTransaction
    });
  }

  const reload = async () => {
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let filteredTransactions = values.allTransaction || [];
    filteredTransactions = filteredTransactions.filter(
      (transaction) => show === 'All' || transaction.type === show
    );
    setValues({ ...values, filteredTransactions });
  }, [show]);

  return {
    values,
    show,
    setShow,
    reload,
    lan
  };
};
export default MyCurrentOrdersLogic;
