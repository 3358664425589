import { useContext } from 'react';
import GeneralContext from '../../../context/GeneralContext';
const MatiPendingLogic = () => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  return {
    lan,
    setGeneralValues
  };
};
export default MatiPendingLogic;
