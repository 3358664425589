import React from 'react';
import './orderMaker.style.css';
import { OrderMakerLogic } from './orderMaker.module';
import CodePages from '../../../classes/CodePages/CodePages';
import Loader from '../Loader/Loader';

export default function OrderMaker(props) {
  const {
    title1Currency,
    title2,
    numberTitle1,
    values,
    DisableButton,
    buttonMessage,
    buttonClassName,
    interfaceSelector,
    coinsText,
    lan,
    setGeneralValues,
    onChange,
    onClick
  } = OrderMakerLogic(props);
  return (
    <main>
      {interfaceSelector === 0 && (
        <section className="main-orderMaker">
          <section className="container-title">
            <p>{title1Currency}:</p>
            <span>{numberTitle1}</span>
            <p>{title2}</p>
          </section>
          <section className="container-currency_options">
            <div className="container-number_selector">
              <p>{coinsText}</p>
              <input
                type="number"
                name="coins"
                id="coins"
                step="1"
                value={values.coins}
                onChange={onChange('coins')}
                min="0"
              />
            </div>
            <div className="container-number_selector">
              <p>{lan.Exchange.higher_price}</p>
              <input
                type="number"
                name="price"
                id="price"
                step="0.01"
                value={values.price}
                onChange={onChange('price')}
                min="0"
              />
            </div>
            <div className="container-number_selector">
              <p>{lan.Exchange.max_usd}</p>
              <div className="number_selector-input3">
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  value={values.amount}
                  min="1"
                />
                <p>
                  2% {lan.Exchange.fee}: USD {values.fee_amount}
                </p>
              </div>
            </div>
          </section>
          <section className="container-button_putOrder">
            <button className={buttonClassName} disabled={DisableButton} onClick={onClick}>
              {buttonMessage}
            </button>
            <p>
              {lan.Exchange.the} <b>{lan.Exchange.one_percent}</b> {lan.Exchange.fee_was_added}
            </p>
          </section>
        </section>
      )}
      {interfaceSelector === 1 && (
        <section className="col-12 pt-2 mt-3 pb-3 mih-16 t-c">
          {/*           <div id="loader" className="loader ">
            <div class="loader-inner ball-scale-ripple-multiple">
              <div className="bc-c6-2" />
              <div className="bc-c6-2" />
              <div className="bc-c6-2" />
            </div>
          </div>
          <div className="col-12 tc-cw-90 fs-1em fs-700 p-0_2">
            {lan.Exchange.processing_request}
          </div> */}
          <Loader />
        </section>
      )}
      {interfaceSelector === 3 && (
        <div className="col-12 pt-2 mt-3 pb-3 mih-16 t-c container-successfully_order">
          <p className="fs-1_4em tc-cw-90">{lan.Exchange.successfully_order}</p>
          <div
            className="col-12 pointer p-0_5 mt-0_5 mb-0_5 pr-2 pl-2 border-radius-24 box-shadow fs-1_2em t-c button-back_exchange"
            onClick={() =>
              setGeneralValues((old_values) => ({
                ...old_values,
                params: 0,
                section: CodePages.EXCHANGE_PAGE
              }))
            }>
            {lan.Exchange.back_to_exchange}
          </div>
        </div>
      )}
    </main>
  );
}
