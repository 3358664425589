import React from 'react';
import './transferToBuy.style.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CC from '../../../assets/svg/cc.svg';
import TransferToBuyLogic from './transferToBuy.logic';
import CodePages from '../../../classes/CodePages/CodePages';
import Loader from '../Loader/Loader';
import ArrowBack from '../ArrowBack/ArrowBack'

export default function TransferToBuy(props) {
  const {
    disableButton,
    interfaceSelector,
    values,
    lan,
    history,
    INTERFACE_DICTIONARY,
    setGeneralValues,
    theMessage,
    onChange
  } = TransferToBuyLogic(props);

  const changeToExchange = () => {
    history.push('/');
    setGeneralValues((old_values) => ({ ...old_values, section: CodePages.EXCHANGE_PAGE }));
  };

  return (
    <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
      <div className="container">
        <div className="col-group">
          <div className="col-4 col-offset-4 p-2 pl-4 pr-4 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-6 bg-cw">
            <ArrowBack />
            <h1 className="col-12 fs-1_6em fs-m-1_4em fw-500 tc-c2 t-c">
              {lan.Exchange.transfer_by}
            </h1>
            <section className="components_section">
              <p className={'credit_card_text'}>{lan.Exchange.credit_card}</p>
              <figure className="flex flex-center">
                <img src={CC} alt={'CC'} className="credit_card-image" />
              </figure>
              {interfaceSelector === INTERFACE_DICTIONARY.DEFAULT && (
                <section>
                  <p className="col-12 fs-0_6em lh-1em fw-600 p-0_2 pt-3 tc-cb uppercase t-c">
                    {lan.Exchange.amount_to_transfer}
                  </p>
                  <div className={`col-12 border-radius-6 bg-cw bc-cb-1 p-0_5 pb-0_2 t-c mb-2`}>
                    <input
                      type="number"
                      step=".01"
                      id="amount"
                      value={values.amount}
                      name="amount"
                      min="0"
                      onChange={onChange}
                      className={`w-100 open-sans pb-0_2 m-0 fs-2_2em lh-1em fw-700 bg-ct bc-none t-c tc-cb`}
                    />
                    <p className="fs-1_2em lh-0_8em fw-600">USD</p>
                  </div>
                  <p className="fs-1em lh-1em tc-cb-90 p-0 t-c">{lan.Exchange.be_aware}</p>
                  <button
                    className="button-credit_card"
                    disabled={disableButton}
                    onClick={theMessage}>
                    {lan.Exchange.go_to_credit_card}
                  </button>
                </section>
              )}
              {interfaceSelector === INTERFACE_DICTIONARY.LOADER && (
                <Loader />
              )}
              {interfaceSelector === INTERFACE_DICTIONARY.ERROR && (
                <section className="col-12 pt-2 pb-3 mih-6 t-c">
                  <p className="fs-1_2em lh-1em tc-cb-90 p-1 bg-cw border-radius-6 bc-c5-1">
                    {lan.Exchange.usd_purchase_error}
                  </p>
                  <button
                    className="col-12 pointer box-shadow p-0_5 pl-1 pr-1 border-radius-24 t-c fs-1_2em lh-m-1em bg-c3 tc-c2"
                    onClick={() =>
                      setGeneralValues((old_values) => ({
                        ...old_values,
                        section: CodePages.EXCHANGE_PAGE
                      }))
                    }>
                    OK
                  </button>
                </section>
              )}
              {interfaceSelector === INTERFACE_DICTIONARY.SUCCESS && (
                <section className="col-12 pt-2 pb-3 mih-6 t-c">
                  <p className="fs-1_2em lh-1em tc-cb-90 p-1 bg-cw border-radius-6 bc-c5-1">
                    {lan.Exchange.purchase_successful_usd}

                  </p>
                  <p className="fs-1_2em lh-1em tc-cb-90 p-1 bc-c5-1">
                    {lan.Exchange.hash_transaction} {values.hash}
                  </p>
                  <button
                    className="col-12 pointer box-shadow p-0_5 pl-1 pr-1 border-radius-24 t-c fs-1_2em lh-m-1em bg-c3 tc-c2"
                    onClick={() => changeToExchange()}>
                    OK
                  </button>
                </section>
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
