class Fee {
  // Para obtener el porcentaje (cantidad * FEE = Porcentaje de comisión)
  static get FEE() {
    return process.env.REACT_APP_FEE;
  }

  // Para mostrar el porcentaje
  static get FORMATTED_FEE() {
    return process.env.REACT_APP_FEE * 100;
  }

  // Para calcular el porcentaje total (cantidad * MATH_FEE = cantidad + el porcentaje)
  static get MATH_FEE() {
    return Number(1) + Number(process.env.REACT_APP_FEE); // 1.02
  }

  static get USD_FEE() {
    return 0.03;
  }

  static get FORMATTED_USD_FEE() {
    return 3;
  }

  static get MATH_USD_FEE() {
    return 1.03;
  }
}

export default Fee;
