import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import SessionService from './Session.service';

function Session() {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    // console.log('Cargo sesión');
    const parsed = queryString.parse(search);
    const { id_session } = parsed;
    const token_in_storage = localStorage.getItem('token');
    const id_in_storage = localStorage.getItem('id');

    if (id_session) {
      // Recuperar la sesión basada en el id_session desde el API
      SessionService()
        .getSessionById(id_session)
        .then((data_session) => {
          console.log({ data_session });
          let { id, token } = data_session;
          id = id === (null || undefined) ? id_in_storage : id;
          localStorage.setItem('id', id);
          token = token === (null || undefined) ? token_in_storage : token;
          localStorage.setItem('token', token);
          history.push('/');
        });
    } else {
      history.push('/');
    }
  }, []);

  return <div />;
}

export default Session;
