import { useContext, useEffect, useState } from 'react';
import { WithdrawUSDService } from './WithdrawUSD.module';
import CodePages from '../../../classes/CodePages/CodePages';
import ExchangeService from '../Exchange/Exchange.service';
import WalletService from '../Wallet/Wallet.service';
import Storage from '../../../classes/Storage/Storage';
import GeneralContext from '../../../context/GeneralContext';

const WithdrawUSD = (props) => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  const { interfaceSelector: interfaceSelectorValue = 0 } = props;
  const [interfaceSelector, setInterfaceSelector] = useState(interfaceSelectorValue);
  const { ButtonSelector: buttonSelectorValue = 0 } = props;
  const [buttonSelector, setButtonSelector] = useState(buttonSelectorValue);
  const [values, setValues] = useState({ withdraw: 0 });
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const onChange = (e) => {
    e.preventDefault();
    const withdraw = e.target.value;
    setValues({ ...values, withdraw });
    setButtonDisabled(values.withdraw <= 0 || values.withdraw > values.availableUSD);
  };
  //interface selector
  //0 = Mensaje important y botones
  //1 = loader
  //2 = solicitud  de withdraw exitosa
  //button selector
  //0= es chileno y no tiene cuenta
  //1= es chileno y tiene cuenta
  //2= extranjeros
  const getDataUser = async () => {
    const user = await ExchangeService().getData();
    const wallet = await WalletService().getData();
    const has_bank_data = user.hasBankData();
    const isChilean = user.isChilean();
    const has_stripe_account = user.hasStripeAccount();
    const { availableUSD } = wallet;
    setValues({
      ...values,
      isChilean,
      has_bank_data,
      has_stripe_account,
      availableUSD,
      withdraw: 0
    });
    if (!isChilean) {
      setButtonSelector(2);
    } else {
      setButtonSelector(isChilean && !has_bank_data ? 0 : 1);
    }
  };

  useEffect(() => {
    getDataUser();
  }, []);

  const Withdraw = async () => {
    setInterfaceSelector(1);
    const transfer_object = {
      id_user: new Storage('id').getItem(),
      amount: values.withdraw
    };
    const transfer = await WithdrawUSDService().createTransfer(transfer_object);
    setGeneralValues((old_values) => ({
      ...old_values,
      section: CodePages.CODE_SENDER_PAGE,
      params: { ...transfer_object, id_transfer: transfer.id },
      callbackFunction: confirm
    }));
  };

  confirm = async (object) => {
    const transfer = {
      code: object.code,
      id: object.transferId
    };
    setInterfaceSelector(1);
    await WithdrawUSDService().confirmTransfer(transfer);
    setGeneralValues((old_values) => ({
      ...old_values,
      section: CodePages.WITHDRAW_PAGE,
      params: { ...transfer, matching: 2 }
    }));
  };

  return {
    interfaceSelector,
    buttonSelector,
    values,
    buttonDisabled,
    lan,
    onChange,
    Withdraw,
    setGeneralValues
  };
};
export default WithdrawUSD;
