import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ExchangeService from '../Exchange/Exchange.service';

function MatiButtonLogic() {
  const [metaDataValues, setMetaDataValues] = useState({});
  const ref = useRef();
  const history = useHistory();

  useEffect(() => {
    async function initialData() {
      const user = await ExchangeService().getData();
      setMetaDataValues({
        user_id: user.id,
        language: 'ing'
      });
    }
    initialData();
  }, []);

  const handler = useCallback((event) => {
    if (event.type === 'mati:userFinishedSdk') {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    const currentRef = ref?.current;

    if (currentRef) {
      currentRef.addEventListener('mati:loaded', handler);
      currentRef.addEventListener('mati:userFinishedSdk', handler);
      currentRef.addEventListener('mati:exitedSdk', handler);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('mati:loaded', handler);
        currentRef.removeEventListener('mati:userFinishedSdk', handler);
        currentRef.removeEventListener('mati:exitedSdk', handler);
      }
    };
  }, [handler]);

  return { metaDataValues, ref };
}

export default MatiButtonLogic;
