import API from '../../../classes/API/API';
import URL from '../../../classes/URL/URL';
import Response from '../../../classes/Response/Response';
import Storage from '../../../classes/Storage/Storage';

const PersonalIdentificationService = () => {
  const getData = async () => {
    const id = new Storage('id').getItem();
    const api = new API(`${URL.URL_SERVER}/users/${id}`);
    const response = new Response(await api.get());
    return response.wasSuccessfull().getSingle();
  };

  const updateData = async (data) => {
    const id = new Storage('id').getItem();
    const api = new API(`${URL.URL_SERVER}/users/${id}`);
    const response = new Response(await api.put(data));
    return response.wasSuccessfull();
  };

  return {
    getData,
    updateData
  };
};
export default PersonalIdentificationService;
