import { useEffect, useState, useContext } from 'react';
import Fee from '../../../classes/Fee/Fee';
import Offer from '../../../classes/Offer/Offer';
import GeneralContext from '../../../context/GeneralContext';

const SuccessfulOperationLogic = (offer) => {
  const { lan } = useContext(GeneralContext);
  const [offerData] = useState(new Offer(offer));
  const [total, setTotal] = useState(0);
  const [fee, setFee] = useState(0);
  const [message, setMessage] = useState('');
  const [operation, setOperation] = useState('');

  useEffect(() => {
    setMessage(offerData.isSell() ? lan.SuccessfulOperation.sold : lan.SuccessfulOperation.deposit);

    setOperation(
      offerData.isSell() ? lan.SuccessfulOperation.coins_sold : lan.SuccessfulOperation.coins_bought
    );
    setTotal(Fee.MATH_FEE * (offerData.amount * offerData.price));
    setFee(Fee.FEE * (offerData.amount * offerData.price));
  });

  return {
    offerData,
    total,
    fee,
    message,
    operation,
    lan
  };
};
export default SuccessfulOperationLogic;
