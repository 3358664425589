import React from 'react';
import ErrorLogic from './Error.logic';
import './Error.styles.css';
import CodePages from '../../../classes/CodePages/CodePages';
import { Link } from 'react-router-dom';

export default function Error() {
  const { lan, setGeneralValues } = ErrorLogic();
  return (
    <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
      <div className="container">
        <div className="col-group">
          <div className="col-4 col-offset-4 p-2 pl-4 pr-4 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-6 bg-cw">
            <section className="errorSection">
              <h2>OOPS!!</h2>
              <h3>{lan.Error.first_message}</h3>
              <div className="errorSection_warning">
                <p>{lan.Error.warning_message}</p>
              </div>
              <span>{lan.Error.please}</span>
              <p>{lan.Error.advice_message}</p>
              <Link className='errorSection_link'  to={'/'}>
                {lan.Error.button}
              </Link>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
