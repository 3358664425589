import API from '../../../classes/API/API';
import URL from '../../../classes/URL/URL';
import Response from '../../../classes/Response/Response';
import Offer from '../../../classes/Offer/Offer';
import CustomError from '../../../classes/Error/CustomError';

const NewOffersService = () => {
  const createOffer = async (offer, opposite = false) => {
    const api = new API(`${URL.URL_SERVER}/offers?full=true`);
    const http_response = await api.post(offer.toCreate({ opposite }));
    const response = new Response(http_response);
    if (!response.hasError()) {
      const data = response.wasSuccessfull().getSingle();
      console.log(data, offer);
      return new Offer({ ...offer, ...data });
    }
    return new CustomError(response.getData());
  };

  const processOffer = async (offer) => {
    const api = new API(`${URL.URL_SERVER}/process-offers`);
    const http_response = await api.post(offer.toProcess());
    const response = new Response(http_response);
    if (!response.hasError()) {
      return response.wasSuccessfull();
    }
    return new CustomError(response.getData());
  };

  const confirmProcessOffer = async (offer, code) => {
    const api = new API(`${URL.URL_SERVER}/confirm-offers`);
    const http_response = await api.post(offer.toConfirm(code));
    const response = new Response(http_response);
    if (!response.hasError()) {
      const data = response.wasSuccessfull().getSingle();
      return new Offer(data);
    }
    return new CustomError(response.getData());
  };

  const deleteOffer = async (id) => {
    const api = new API(`${URL.URL_SERVER}/offers/${id}`);
    const http_response = await api.delete();
    const response = new Response(http_response);
    if (!response.hasError()) {
      return response.wasSuccessfull();
    }
    return new CustomError(response.getData());
  };

  return {
    createOffer,
    processOffer,
    confirmProcessOffer,
    deleteOffer
  };
};

export default NewOffersService;
