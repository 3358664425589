import CodePages from './CodePages/CodePages';

class ChooserPage {
  static get PAGES_DICTIONARY() {
    return {
      starter: CodePages.SELECT_ACCOUNT_PAGE,
      succeeded: CodePages.EXCHANGE_PAGE,
      data_loaded: CodePages.MATI_PAGE,
      rejected: CodePages.MATI_PAGE,
      pending: CodePages.MATI_PENDING,
      has_extras: {
        succeeded: CodePages.EXCHANGE_PAGE,
        rejected: CodePages.MATI_PAGE,
        pending: CodePages.MATI_PENDING,
        data_loaded: CodePages.MATI_PAGE
      }
    };
  }

  constructor({ verified, hasExtras }) {
    console.log({ verified, hasExtras });
    this.verified = verified;
    this.hasExtras = hasExtras;
  }

  goToPage() {
    return this.hasExtras
      ? ChooserPage.PAGES_DICTIONARY['has_extras'][`${this.verified}`]
      : ChooserPage.PAGES_DICTIONARY[`${this.verified}`];
  }
}

export default ChooserPage;
