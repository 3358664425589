import React  from "react";
import LoaderLogic from "./Loader.logic";

export default function Loader() {
  const {  lan } = LoaderLogic();
  return(
    <>
            <div className="col-offset-2 col-8 col-mb-12 p-0 position-relative">
              <figure id="loader" className="loader">
                <div className="loader-inner  ball-scale-ripple-multiple">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </figure>
              <div className="loading-text color3 t-c">
                {lan.Checkout.loading}
              </div>
            </div>         
    </>
  )  
}