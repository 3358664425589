import { useState } from 'react';
import StripeConnectService from './StripeConnect.service';
import { ExchangeService } from '../Exchange/Exchange.module';

const StripeConnectLogic = (props) => {
  const [values] = useState({
    activeClass: props.disabled
      ? 'col-12 box-shadow p-0_5 pl-1 pr-1 border-radius-24 t-c fs-1_2em lh-m-1em bg-c5 tc-ci-90'
      : 'col-12 pointer box-shadow p-0_5 pl-1 pr-1 border-radius-24 t-c fs-1_2em lh-m-1em bg-c3 tc-c2-90'
  });

  const stripeConnect = async () => {
    const user = await ExchangeService().getData();

    if (user.hasStripeAccount()) {
      const url = await StripeConnectService().getStripeAccount(user.account_stripe_connect);
      return (window.location.href = url);
    } else {
      const url = await StripeConnectService().createStripeAccount(user.id);
      return (window.location.href = url);
    }
  };

  return {
    stripeConnect,
    values
  };
};

export default StripeConnectLogic;
