import { useContext, useEffect, useState } from 'react';
import ExchangeService from '../Exchange/Exchange.service';
import WalletService from '../Wallet/Wallet.service';
import GeneralContext from '../../../context/GeneralContext';

const CodeSenderLogic = () => {
  const { lan, setGeneralValues, callBackFunction, params } = useContext(GeneralContext);
  const [user, setUser] = useState({});
  const [wallet, setWallet] = useState({});
  const [code, setCode] = useState(null);
  const [values, setValues] = useState({ loading: false, verifyCode: false, failCode: false });

  async function getDataUser() {
    const user = await ExchangeService().getData();
    const wallet = await WalletService().getData();
    setUser(user);
    setWallet(wallet);
  }

  async function confirmProcess() {
    setValues({ ...values, loading: true, failCode: false });
    const offer = params;
    await callBackFunction({
      offerId: offer.id_offer,
      transferId: offer.id_transfer,
      status: 201,
      code,
      offer
    });
    setValues({ ...values, loading: false });
  }

  async function onChange(e) {
    const code = e.target.value;
    if (code || code !== '') {
      setValues({ ...values, verifyCode: true });
      setCode(code);
    } else {
      setValues({ ...values, verifyCode: false, failCode: false });
    }
  }

  useEffect(() => {
    getDataUser();
  }, []);

  return {
    user,
    wallet,
    values,
    lan,
    setGeneralValues,
    confirmProcess,
    onChange
  };
};

export default CodeSenderLogic;
