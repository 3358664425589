import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainText: {
    fontWeight: '500',
    fontSize: '1.2em',
    paddingRight: '1em !important',
    color: '#020C72',
    fontFamily: 'Montserrat'
  }
}));

export default useStyles;
