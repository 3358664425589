import { useContext, useEffect, useState } from 'react';
import CheckoutService from './/Checkout.service';
import GeneralContext from '../../../context/GeneralContext';

const SECTION_DICTIONARY = {
  LOADER: 1,
  SUCCESS: 8, // Deprecated
  ERROR: 9,
  SUCCESS_USD: 10
};

const CheckoutLogic = ({ page, session_id }) => {
  const { setGeneralValues, transaction } = useContext(GeneralContext);
  const [section, setSection] = useState(page);

  useEffect(() => {
    getFinishPay();
  }, []);

  const getFinishPay = async () => {
    if (section === SECTION_DICTIONARY.ERROR) {
      return;
    }
    const transaction = await CheckoutService.getById(session_id);
    setGeneralValues((old_values) => ({ ...old_values, transaction }));
    if (transaction.type === 'buy_usd' || Number(transaction.amount_usd) > 0) {
      // Venta de dólares, Carga TransferToBuy y ahí procesa el checkPay
      setSection(SECTION_DICTIONARY.SUCCESS_USD);
    }
  };

  return {
    section,
    transaction,
    SECTION_DICTIONARY
  };
};

export default CheckoutLogic;
