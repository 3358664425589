import API from '../../../classes/API/API';
import URL from '../../../classes/URL/URL';
import Response from '../../../classes/Response/Response';
import CustomError from '../../../classes/Error/CustomError';
import Storage from '../../../classes/Storage/Storage';
import Wallet from '../../../classes/Wallet/Wallet';

const WalletService = () => {
  const getData = async () => {
    const id = new Storage('id').getItem();
    const api = new API(`${URL.URL_SERVER}/users/${id}/wallets`);
    const http_response = await api.get();
    const response = new Response(http_response);
    if (!response.hasError()) {
      const data = response.wasSuccessfull().getSingle();
      return new Wallet(data);
    }
    return new CustomError(response.getData());
  };

  return {
    getData
  };
};
export default WalletService;
