import React from "react";
import CodePages from '../../../classes/CodePages/CodePages';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowBackLogic from "./ArrowBack.logic";

export default function BackArrow({ section = CodePages.EXCHANGE_PAGE }) {
  const { lan, setGeneralValues } = ArrowBackLogic();
  return (
    <div
      className="p-0_5 flex align-item-center flex-start cursor-pointer"
      onClick={() =>
        setGeneralValues((old_values) => ({
          ...old_values,
          params: 0,
          section
        }))
      }>
      <ArrowBackIosIcon className="fc-c6" />
      <span className="tc-c6 fw-500 fs-0_8em">{lan.Exchange.back}</span>
    </div>
  )
}