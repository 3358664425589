import API from '../../../classes/API/API';
import Storage from '../../../classes/Storage/Storage';
import User from '../../../classes/User/User';
import Response from '../../../classes/Response/Response';
const url = `${process.env.REACT_APP_API}`;

const ExchangeService = () => {
  const getData = async () => {
    const id = new Storage('id').getItem();
    const api = new API(`${url}/users/${id}`);
    const response = new Response(await api.get());
    const data = response.wasSuccessfull().getSingle();
    return new User(data);
  };

  return {
    getData
  };
};

export default ExchangeService;
