import { useContext } from 'react';
import GeneralContext from '../../../context/GeneralContext';
const MatiPageLogic = () => {
  const { lan, setGeneralValues } = useContext(GeneralContext);
  return {
    lan,
    setGeneralValues
  };
};
export default MatiPageLogic;
