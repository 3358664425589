import React from 'react';
import { WithdrawUSDLogic } from './WithdrawUSD.module';
import CodePages from '../../../classes/CodePages/CodePages';
import './Withdraw.styles.css';
import StripeConnect from '../../Exchange/StripeConnect/StripeConnect';
import ArrowBack from '../ArrowBack/ArrowBack'

const WithdrawUSD = (props) => {
  const {
    buttonSelector,
    buttonDisabled,
    interfaceSelector,
    lan,
    values,
    setGeneralValues,
    onChange,
    Withdraw
  } = WithdrawUSDLogic(props);
  return (
    <>
      <div className="pt-3 p-m-1 pb-4 pb-m-3 mhw">
        <div className="container">
          <div className="col-group">
            <div className="col-8 col-dt-4 col-offset-2 col-dt-offset-4 p-0 pt-0_2 border-radius-tl-6 border-radius-tr-6 bg-cw flex align-left">
              <ArrowBack/>
            </div>
            <main className="col-8 col-dt-4 col-offset-2 col-dt-offset-4 p-2 pt-1 pt-m-0 pl-m-1 pr-m-1 pb-4 pb-m-3 border-radius-bl-6 border-radius-br-6 bg-cw">
              <h3>{lan.Exchange.transfer_to_bank_account}</h3>
              <section className="container_blue">
                <div className="number_box">
                  <h4 className="number_text">{lan.Exchange.current_balance}</h4>
                  <span className="number_current">{values.availableUSD}</span>
                  <span className="Number_unit">USD</span>
                </div>
                <div className="amount-to-withdraw">
                  <h4 className="number_text">{lan.Exchange.amount_to_withdraw}</h4>
                  <input
                    id="withdraw"
                    type="number"
                    step="0.01"
                    min="0"
                    max={values.availableUSD}
                    value={values.withdraw}
                    name="withdraw"
                    onChange={onChange}
                  />
                  <span className="Number_unit">USD</span>
                </div>
              </section>
              {interfaceSelector === 0 && (
                <section className="register_button">
                  <p>
                    <span className="fs-1em lh-1em fw-600">{lan.Exchange.important}:</span>{' '}
                    {lan.Exchange.chilean_investors}
                  </p>
                  {buttonSelector === 0 && (
                    <button
                      className="col-12 pointer box-shadow p-0_5 pl-1 pr-1 border-radius-24 t-c fs-1_2em lh-m-1em bg-c3 tc-c2-90"
                      onClick={() =>
                        setGeneralValues((old_values) => ({
                          ...old_values,
                          section: CodePages.SETUP_ACCOUNT_BANK_DATA
                        }))
                      }>
                      {lan.Exchange.register_account}
                    </button>
                  )}
                  {buttonSelector === 1 && (
                    <button
                      className="button-withdraw"
                      onClick={() => Withdraw()}
                      disabled={buttonDisabled}>
                      {lan.Exchange.withdraw}
                    </button>
                  )}
                  {buttonSelector === 2 && <StripeConnect />}
                </section>
              )}
              {interfaceSelector === 1 && (
                <div className="col-12 pt-2 mt-3 pb-3 mih-6 t-c">
                  <div id="loader" className="loader ">
                    <div className="loader-inner ball-scale-ripple-multiple">
                      <div className="bc-c2-2" />
                      <div className="bc-c2-2" />
                      <div className="bc-c2-2" />
                    </div>
                  </div>
                  <div className="col-12 tc-c2 fs-1em fs-700 p-0_2">
                    {lan.Exchange.processing_request}
                  </div>
                </div>
              )}
              {interfaceSelector === 2 && (
                <div className="col-12 pt-2 pb-3 mih-6 t-c">
                  <p className="fs-1_2em lh-1em tc-c2-90 p-0">
                    {lan.Exchange.withdraw_successfully}
                  </p>
                  <p className="fs-1_2em lh-1em tc-c2-90 p-0">{lan.Exchange.new_fees}</p>
                  <div
                    className="col-12 pointer box-shadow p-0_5 pl-1 pr-1 border-radius-24 t-c fs-1_2em lh-m-1em bg-c3 tc-c2"
                    onClick={() =>
                      setGeneralValues((old_values) => ({
                        ...old_values,
                        section: CodePages.EXCHANGE_PAGE
                      }))
                    }>
                    OK
                  </div>
                </div>
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawUSD;
